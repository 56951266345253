








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CookieCategories, CookieKeys } from '../../composables/cookies';

@Component
export default class CookieWallSection extends Vue {
    @Prop({ required: true, type: Object })
    categories!: CookieCategories<CookieKeys>;

    @Prop({ required: true, type: String })
    essentialLabel!: string;
}
