











































































import { getSelectorClass } from '@/utils';
import { unique } from '@/utils/array';
import {
    BaseMetadataItem,
    Metadata,
    MetadataType,
    PartialMetadata,
} from '@openticket/sdk-shop';
import { TranslateResult } from '@openticket/vue-localization';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import DateAlt from '@/components/DateAlt.vue';

/**
 * MetaInput is the wrapper component for handling a metadata
 * item: used for booker information and metadata.
 */
@Component({
    components: { DateAlt },
})
export default class MetaInput<I> extends Vue {
    @Prop()
    meta!: BaseMetadataItem & { readonly item: Partial<Metadata> };

    @Prop({ default: true })
    showName!: boolean;

    @Prop()
    disabled!: boolean;

    @Prop({ default: null })
    bookerInfoId!: string | null;

    @Ref('input')
    input!: HTMLInputElement;

    // True if this input has been validated with errors at least once
    isInitialized = false;
    localeChangeHandler = 0;

    private get metaSelectorClass(): string {
        if (this.bookerInfoId) {
            return getSelectorClass('booker-info', this.bookerInfoId);
        }

        if (this.meta.item.guid) {
            return getSelectorClass('metadata', this.meta.item.guid);
        }

        return '';
    }

    created(): void {
        // Make the meta-object observable to watch errors
        Vue.observable(this.meta);

        // This is to make sure the error values are also translated
        this.meta.item.translateName = this.translatedName;
    }

    mounted(): void {
        this.localeChangeHandler = this.$localization.on(
            'locale-change',
            () => {
                // This is to make sure the error values are also translated
                this.meta.item.translateName = this.translatedName;

                if (this.meta.errors.length) {
                    this.validate(this.meta);
                }
            }
        );
    }

    destroyed(): void {
        this.$localization.off(this.localeChangeHandler);
    }

    onInput(val: I): void {
        if (this.meta.errors.length || this.isInitialized) {
            this.validate(this.meta);
        }

        this.$emit('input', val);
    }

    onBlur(val: I): void {
        this.validate(this.meta);

        this.isInitialized = true;

        this.$emit('blur', val);
    }

    private get parsedValue(): any {
        if (this.meta.item.type === MetadataType.Boolean) {
            if (this.meta.value === null) {
                return null;
            }

            if (this.meta.item.extra.includes('required')) {
                if (
                    this.meta.value === 'false' ||
                    this.meta.value === '0' ||
                    !this.meta.value
                ) {
                    return 'false';
                }

                return 'true';
            }

            return (
                this.meta.value !== 'false' &&
                this.meta.value !== '0' &&
                !!this.meta.value
            );
        }

        if (this.meta.item.type === MetadataType.Values) {
            if (typeof this.meta.value === 'string') {
                this.meta.value = this.meta.value.length
                    ? this.meta.value.split(',').map(val => val.trim())
                    : [];
            }
        }

        return this.meta.value;
    }

    private set parsedValue(val: any) {
        if (this.meta.item.type === MetadataType.Boolean) {
            if (val === null) {
                this.meta.value = null;
            } else if (val === 'true') {
                this.meta.value = true;
            } else if (val === 'false' || val === '0') {
                this.meta.value = false;
            } else {
                this.meta.value = !!val;
            }
        } else {
            this.meta.value = val;
        }
    }

    get label(): string | undefined {
        if (!this.showName || this.inputType === 'checkbox') {
            return undefined;
        }

        return this.translatedName;
    }

    get description(): string | undefined {
        if (!this.meta.item.shop_description) {
            return undefined;
        }

        return this.translateValueWithComputedSlug(
            this.meta.item.shop_description,

            // TODO Composing a slug is something we'd rather not do.
            //  Find some way to do this differently (probably map hardcoded values, or have full slug in the description...)
            (value: string) => `shop.common.metaDataDescription.${value}`
        );
    }

    get translatedName(): string {
        return this.translateValueWithComputedSlug(
            this.meta.item.name,

            // TODO Composing a slug is something we'd rather not do.
            //  Find some way to do this differently (probably map hardcoded values, or have full slug in the name...)
            (value: string) => `shop.common.metaData.${value}`
        );
    }

    translateValueWithComputedSlug(
        value: string,
        slugFn: (value: string) => string
    ): string {
        let translatedValue: string = this.$t(value) as string;

        if (translatedValue !== value) {
            return translatedValue;
        }

        const slug = slugFn(value);

        translatedValue = this.$t(slug) as string;

        if (translatedValue !== slug) {
            return translatedValue;
        }

        return value;
    }

    get date(): { after: Date | null; before: Date | null } {
        if (
            this.meta.item.type !== MetadataType.Date ||
            !this.meta.item.extra
        ) {
            return {
                after: null,
                before: null,
            };
        }

        const restrictions: { after: Date | null; before: Date | null } = {
            after: null,
            before: null,
        };

        for (const rule of this.meta.item.extra) {
            // direction -> 'before' or 'after'
            // equality -> does the date include the limit date
            // whenRelative -> relative date, like 'today'
            // whenAbsolute -> absolute date, exact date
            const [, direction, equality, whenRelative, whenAbsolute] =
                rule.match(
                    /^(after|before)(_or_equal)?:(?:(yesterday|today|tomorrow)|([0-9]{4}-[0-9]{2}-[0-9]{2}))/
                ) || [];

            if (!direction || !(whenRelative || whenAbsolute)) {
                continue;
            }

            // For absolute date parsing, the time is appended.
            // - Date only forms will be interpreted as UTC.
            // - Date-time forms wil be interpreted as the user's timezone.
            // https://maggiepint.com/2017/04/11/fixing-javascript-date-web-compatibility-and-reality/
            let date: Date = whenRelative
                ? new Date('NaN')
                : new Date(`${whenAbsolute}T00:00:00`);

            const relativeDayOffset: { [key: string]: number } = {
                yesterday: -1,
                today: 0,
                tomorrow: 1,
            };

            if (whenRelative && whenRelative in relativeDayOffset) {
                date = new Date();
                date.setDate(date.getDate() + relativeDayOffset[whenRelative]);
            }

            if (isNaN(date.valueOf())) {
                continue;
            }

            if (direction === 'after') {
                if (!equality) {
                    // The dates returned should form an inclusive range.
                    // i.e. the returned dates SHOULD be valid (given that the range contains at least one date).
                    date.setDate(date.getDate() + 1);
                }

                if (restrictions.after && restrictions.after > date) {
                    // If a rule is present multiple times, all of them should be checked.
                    // Values are only valid if they pass all rules.
                    continue;
                }
            } else if (direction === 'before') {
                if (!equality) {
                    // The dates returned should form an inclusive range.
                    // i.e. the returned dates SHOULD be valid (given that the range contains at least one date).
                    date.setDate(date.getDate() - 1);
                }

                if (restrictions.before && restrictions.before < date) {
                    // If a rule is present multiple times, all of them should be checked.
                    // Values are only valid if they pass all rules.
                    continue;
                }
            } else {
                continue;
            }

            restrictions[direction] = date;
        }

        return restrictions;
    }

    validate(metadata: BaseMetadataItem): void {
        if (this.$shop && this.$shop.cart) {
            this.$shop.cart.validator.metadata(metadata, true);
        } else {
            throw Error('No validator found');
        }
    }

    // Map the metadata type to specific components
    get inputType(): string {
        switch (this.meta.item.type) {
            case MetadataType.Enum:
                return 'select';
            case MetadataType.Values:
                return 'select';
            case MetadataType.EnumOther:
                return 'select-other';
            case MetadataType.Boolean:
                if (this.meta.item.extra.includes('required')) {
                    return 'selectbar';
                }
                return 'checkbox';
            case MetadataType.String:
                if (this.meta.item.extra.includes('email')) {
                    return 'email';
                }
                return 'text';
            case MetadataType.Phone:
                return 'phone';
            case MetadataType.Integer:
                return 'integer';
            case MetadataType.Date:
                return 'date-alt';
            default:
                throw Error(
                    `Metadata type ${this.meta.item.type} is not supported`
                );
        }
    }

    // Return the basic props for this metadata item
    get props(): { [key: string]: any } {
        return {
            name: this.meta.item.name,
            label: this.meta.item.translateName,
            errors: this.meta.errors,
            ...this.typeProps,
        };
    }

    // Return type specific props
    get typeProps(): { [key: string]: any } {
        const translateOrOriginal = (
            value: string,
            slugPrefix: string
        ): string => {
            const slug = `${slugPrefix.replace(/\.+$/, '')}.${value}`;
            const translateValue: TranslateResult = this.$t(slug);

            if (typeof translateValue === 'string' && translateValue !== slug) {
                return translateValue;
            }

            return value;
        };

        function getOptions(item: PartialMetadata): { [key: string]: string } {
            const inRule: undefined | string = item.extra.find((rule: string) =>
                rule.startsWith('in:')
            );

            if (!inRule) {
                return {};
            }

            const uniqueOptions: Array<[string, string]> = inRule
                .replace(/^in:/, '')
                .split(',')
                .map((option: string) => option.trim())
                .filter(unique)
                .map((option: string) => [
                    option,
                    translateOrOriginal(
                        option,
                        `shop.common.metaData_options.${item.name}`
                    ),
                ]);

            return Object.fromEntries(uniqueOptions);
        }

        switch (this.meta.item.type) {
            case MetadataType.Enum:
                return {
                    options: getOptions(this.meta.item),
                    placeholder: this.$t(
                        'shop.components.meta_input.enum.placeholder'
                    ),
                    searchLabel: this.$t('shop.components.meta_input.search'),
                    selectionLabel: (values: string[], isOpen: boolean) => {
                        if (!this.meta.value && isOpen) {
                            return this.$tc(
                                'shop.components.meta_input.enum.selected',
                                values.length
                            );
                        }

                        return '';
                    },
                };
            case MetadataType.EnumOther:
                return {
                    options: getOptions(this.meta.item),
                    otherLabel: this.$t(
                        'shop.common.metaData_options.enumOther.other'
                    ),
                    placeholder: this.$t(
                        'shop.components.meta_input.enum_other.placeholder'
                    ),
                    searchLabel: this.$t('shop.components.meta_input.search'),
                    selectionLabel: (
                        values: string[],
                        isOpen: boolean,
                        key: string
                    ) => {
                        if (!this.meta.value && isOpen && key !== '__other__') {
                            return this.$tc(
                                'shop.components.meta_input.enum_other.selected',
                                values.length
                            );
                        }

                        return '';
                    },
                };
            case MetadataType.Values:
                return {
                    options: getOptions(this.meta.item),
                    multiple: true,
                    placeholder: this.$t(
                        'shop.components.meta_input.values.placeholder'
                    ),
                    searchLabel: this.$t('shop.components.meta_input.search'),
                    selectionLabel: (values: string[], isOpen: boolean) => {
                        if (values.length || isOpen) {
                            return this.$tc(
                                'shop.components.meta_input.values.selected',
                                values.length
                            );
                        }

                        return '';
                    },
                };
            case MetadataType.Boolean:
                return {
                    label: `${this.meta.item.translateName}${
                        this.required
                            ? '<span class="ot-input-label--required">*</span>'
                            : ''
                    }`,
                    options: {
                        false: this.$t(
                            'shop.common.metaData_options.boolean.false'
                        ) as string,
                        true: this.$t(
                            'shop.common.metaData_options.boolean.true'
                        ) as string,
                    },
                };
        }

        return {};
    }

    get required(): boolean {
        return (
            this.meta.item.extra.includes('required') ||
            (this.meta.item.type === MetadataType.Boolean &&
                this.meta.item.extra.includes('accepted'))
        );
    }
}
