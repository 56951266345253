import Vue from 'vue';

import CartReview from './CartReview.vue';
import CouponsModule from './Coupons.vue';
import EventsView from './Events.vue';
import TicketView from './Ticket.vue';
import TicketsView from './Tickets.vue';
import ProductsView from './Products.vue';
import FacebookPageCard from './FacebookPageCard.vue';
import PaymentsView from './Payment.vue';

import TermsModule from './Terms.vue';
import BookerInformation from './BookerInformation.vue';
import TicketMetadata from './TicketMetadata.vue';
import OrderMetadataItem from './OrderMetadataItem.vue';
import TicketCollapse from './TicketCollapse.vue';
import Paragraph from './Paragraph.vue';
import ProductItem from './ProductItem.vue';
import Timeslots from './Timeslots.vue';
import ProductGroup from './ProductGroup.vue';

import GreenProduct from './globalProducts/GreenProduct.vue';
import RefundProtect from './globalProducts/RefundProtect.vue';
import Giro555 from './globalProducts/Giro555.vue';
import CoverGenius from './globalProducts/CoverGenius.vue';

import './external';

Vue.component('shop-module-cart-review', CartReview);
Vue.component('shop-module-coupons', CouponsModule);
Vue.component('shop-module-events', EventsView);
Vue.component('shop-module-ticket', TicketView);
Vue.component('shop-module-tickets', TicketsView);
Vue.component('shop-module-products', ProductsView);
Vue.component('shop-module-terms', TermsModule);
Vue.component('shop-module-booker-information', BookerInformation);
Vue.component('shop-module-ticket-metadata', TicketMetadata);
Vue.component('shop-module-ticket-collapse', TicketCollapse);
Vue.component('shop-module-facebook', FacebookPageCard);
Vue.component('shop-module-payment', PaymentsView);
Vue.component('shop-module-order-metadata-item', OrderMetadataItem);
Vue.component('shop-module-paragraph', Paragraph);
Vue.component('shop-module-product-item', ProductItem);
Vue.component('shop-module-product-group', ProductGroup);
Vue.component('shop-module-timeslots', Timeslots);

// Global product cards
Vue.component('shop-module-green-product', GreenProduct);
Vue.component('shop-module-refund-protect', RefundProtect);
Vue.component('shop-module-giro-555', Giro555);
Vue.component('shop-module-cover-genius', CoverGenius);
