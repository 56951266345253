import Vue from 'vue';
import MetaInput from './MetaInput.vue';
import SplashScreen from './SplashScreen.vue';
import Footer from './Footer.vue';
import BrandedHeader from './BrandedHeader.vue';
import Collapse from './Collapse.vue';
import Logo from './Logo.vue';
import Spinner from './Spinner.vue';

// GENERAL COMPONENTS
Vue.component('BrandedHeader', BrandedHeader);
Vue.component('SplashScreen', SplashScreen);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Footer', Footer);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Collapse', Collapse);
Vue.component('MetaInput', MetaInput);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Logo', Logo);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Spinner', Spinner);
