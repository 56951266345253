import { IFramePage, PageInfo } from 'iframe-resizer';

export function isIframeResizerContentWindow(
    value: unknown
): value is { parentIFrame: IFramePage } {
    if (!value || typeof value !== 'object' || Array.isArray(value)) {
        return false;
    }

    const { parentIFrame } = value as { parentIFrame?: unknown };

    return !!parentIFrame;
}

export function processIframePageInfo(): void {
    if (isIframeResizerContentWindow(window)) {
        window.parentIFrame.getPageInfo(
            (
                data: PageInfo & {
                    documentHeight?: number;
                    documentWidth?: number;
                }
            ) => {
                const documentStyle = document.documentElement.style;

                if (data.documentHeight || data.clientHeight) {
                    documentStyle.setProperty(
                        '--ot-injector-iframe-parent-vh',
                        `${data.documentHeight || data.clientHeight}px`
                    );
                } else {
                    documentStyle.removeProperty(
                        '--ot-injector-iframe-parent-vh'
                    );
                }

                if (data.offsetTop) {
                    documentStyle.setProperty(
                        '--ot-injector-iframe-offset-top',
                        `${data.offsetTop}px`
                    );
                } else {
                    documentStyle.removeProperty(
                        '--ot-injector-iframe-offset-top'
                    );
                }
            }
        );
    } else {
        console.debug('Not an iframe resizer content window');
    }
}

export function registerIframeResizerListener(): void {
    window.iFrameResizer = {
        onReady: function() {
            if (isIframeResizerContentWindow(window)) {
                processIframePageInfo();
            } else {
                console.warn(
                    'Not an iframe resizer content window, eventhough called from iFrameResizer.onReady!'
                );
            }
        },
    };

    processIframePageInfo();
}
