import { render, staticRenderFns } from "./CartReviewGlobalProduct.vue?vue&type=template&id=7b4b79f8&scoped=true&"
import script from "./CartReviewGlobalProduct.vue?vue&type=script&lang=ts&"
export * from "./CartReviewGlobalProduct.vue?vue&type=script&lang=ts&"
import style0 from "./CartReviewGlobalProduct.vue?vue&type=style&index=0&id=7b4b79f8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b4b79f8",
  null
  
)

export default component.exports