





























import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { OtInputBase } from '@openticket/vue-input';

// source: https://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
// Month here is 1-indexed (January is 1, February is 2, etc). This is
// because we're using 0 as the day so that it returns the last day
// of the last month, so you have to add 1 to the month number
// so it returns the correct amount of days
function daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
}

const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, '0');

@Component
export default class DateAlt extends OtInputBase<string> {
    @Prop({ default: [] }) rules!: string[];

    @Prop({ default: null }) before!: Date | null;

    @Prop({ default: null }) after!: Date | null;

    @Prop({ default: false }) invalid!: boolean;

    private year: number | null = null;

    private month: number | null = null;

    private day: number | null = null;

    created(): void {
        this.onValueChangeDate();
    }

    private get years(): number[] {
        const max = this.before
            ? this.before.getFullYear()
            : new Date().getFullYear() + 20;

        const min = this.after
            ? this.after.getFullYear()
            : new Date().getFullYear() - 150;

        const years: number[] = [];

        for (let i = max; i >= min; i--) {
            years.push(i);
        }

        return years;
    }

    private get months(): number[] {
        let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        if (this.before && this.before.getFullYear() === this.year) {
            const maxMonth = this.before.getMonth();
            months = months.slice(0, maxMonth + 1);
        }

        if (this.after && this.after.getFullYear() === this.year) {
            const minMonth = this.after.getMonth();
            months = months.slice(minMonth);
        }

        return months;
    }

    private get days(): number[] {
        if (!this.year || !this.month) {
            return [];
        }

        const max =
            this.before &&
            this.before.getFullYear() === this.year &&
            this.before.getMonth() + 1 === this.month
                ? this.before.getDate()
                : daysInMonth(this.month, this.year);

        const min =
            this.after &&
            this.after.getFullYear() === this.year &&
            this.after.getMonth() + 1 === this.month
                ? this.after.getDate()
                : 1;

        const days: number[] = [];

        for (let i = max; i >= min; i--) {
            days.push(i);
        }

        return days;
    }

    @Watch('value')
    private onValueChangeDate() {
        if (!this.value) {
            this.year = null;
            this.month = null;
            this.day = null;
            return;
        }

        const split = this.value.split('-');

        const newYear = parseInt(split[0]);
        if (this.year !== newYear) {
            this.year = newYear;
        }

        const newMonth = parseInt(split[1]);
        if (this.month !== newMonth) {
            this.month = newMonth;
        }

        const newDay = parseInt(split[2]);
        if (this.day !== newDay) {
            this.day = newDay;
        }
    }

    @Watch('year')
    @Watch('month')
    @Watch('day')
    private onChangeDate() {
        if (this.year && this.month && this.day) {
            const date = [
                this.year,
                zeroPad(this.month, 2),
                zeroPad(this.day, 2),
            ].join('-');
            this.$emit('input', date);
        }
    }

    @Watch('months')
    private checkMonth(): void {
        if (this.month && !this.months.includes(this.month)) {
            this.month = null;
        }
    }

    @Watch('days')
    private checkDay(): void {
        if (this.day && !this.days.includes(this.day)) {
            this.day = null;
        }
    }
}
