





























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CollapseCard extends Vue {
    @Prop() title!: string;
    @Prop({ default: 'default' }) headerColor!:
        | 'overlay'
        | 'primary'
        | 'secondary'
        | 'default';
    @Prop({ default: 'medium' }) padding!: 'extra-small' | 'small' | 'medium';
    @Prop({ default: true }) opened!: boolean;

    isOpened = false;

    created(): void {
        this.isOpened = this.opened;
    }

    toggle(): void {
        this.isOpened = !this.isOpened;
    }
}
