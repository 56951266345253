import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { OpenTicketShop } from '@openticket/sdk-shop';

interface ShopModuleShopContentsFilter {
    ticket_ids?: string[];
    ticket_names?: string[];
    event_ids?: string[];
    event_names?: string[];
}
export interface ShopModuleShopContentModifiers {
    whitelist?: ShopModuleShopContentsFilter;
    blacklist?: ShopModuleShopContentsFilter;
    coupon?: string;
}

export interface ShopModuleValidationError {
    module?: string;
    message: string;
    slug?: string;
    slugData?: { [key: string]: string | number };
    payload?: any;
}

export interface ShopModuleNotReady {
    scopes: string[];
    message: string;
    slug?: string;
    slugData?: { [key: string]: string | number };
}

export default class ShopModuleComponent extends Vue {
    public isDirty = false;
    public scopes: string[] = [];

    @Prop({ default: null })
    shopContentModifiers!: null | ShopModuleShopContentModifiers;

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        mutate; // TODO @rik Does this belong here ???
        return null;
    }

    public static isReady: (shop: OpenTicketShop) => ShopModuleNotReady | null;

    @Watch('isDirty')
    protected onChangeIsValid(): void {
        this.$emit('isDirty', this.isDirty);
    }

    protected changeShopContentModifiers(
        newModifiers: ShopModuleShopContentModifiers | null
    ): void {
        this.$emit('changeShopContentModifiers', newModifiers);
    }
}
