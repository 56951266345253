
































































import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive, Prop } from 'vue-property-decorator';
import { TranslateResult } from '@openticket/vue-localization';

@Component
export default class ShopError extends Vue {
    @InjectReactive('baseInitialized')
    baseInitialized!: boolean;

    @Prop()
    title!: string;

    @Prop()
    description!: string;

    created(): void {
        let sessionId = this.$route.query.sessionId;

        if (!sessionId) {
            this.$router.replace({
                path: this.$route.path,
                query: {
                    ...this.$route.query,
                    sessionId: this.$logger.sessionId,
                },
            });
        }
    }

    get type(): string {
        return 'Shop';
    }

    get shownTitle(): TranslateResult {
        if (this.$route.query.title) {
            const translated = this.$t(this.$route.query.title as string);
            if (translated !== this.$route.query.title) {
                return translated;
            }
        }

        if (this.title) {
            return this.$t(this.title);
        }

        return this.$t('shop.components.shop_error.title');
    }

    get shownDescription(): TranslateResult {
        if (this.$route.query.description) {
            const translated = this.$t(this.$route.query.description as string);
            if (translated !== this.$route.query.description) {
                return translated;
            }
        }

        if (this.description) {
            return this.$t(this.description);
        }

        return this.$t('shop.components.shop_error.description');
    }

    get redirectUrl(): string {
        const url = this.$route.query.redirect as string;
        if (url) {
            const resolved = this.$router.resolve({
                path: url,
                query: {
                    sessionId: this.$logger.sessionId,
                },
            }).resolved;
            if (resolved.name !== 'error') {
                return resolved.fullPath;
            }
        }

        return '';
    }
}
