












































import Component from 'vue-class-component';
import OptionalItem from '@/pages/shop/components/OptionalProductGroup/OptionalItem.vue';
import { Prop } from 'vue-property-decorator';
import ShopModuleComponent, { ShopModuleValidationError } from '../../module';
import CollapseCard from '@/pages/shop/components/CollapseCard.vue';
import { CartTicket, ProductGroup } from '@openticket/sdk-shop';

@Component({
    components: {
        OptionalItem,
        CollapseCard,
    },
})
export default class SwinkelsGroupBeerPackage extends ShopModuleComponent {
    @Prop() productGroupNames!: string[];
    @Prop() assets!: string;
    @Prop() description!: string;

    cartTicket!: CartTicket;

    created(): void {
        this.cartTicket = Object.values(this.$shop.cart.flatItems.tickets)[0];
    }

    public static isReady(): null {
        return null;
    }

    public validate(): ShopModuleValidationError | null {
        return null;
    }

    get productGroup(): ProductGroup {
        for (const group of this.$shop.data.groups) {
            if (!group.name) {
                continue;
            }

            if (this.productGroupNames.includes(group.name)) {
                return group;
            }
        }

        throw Error('ProductGroup not found for menu');
    }
}
