












































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class DateBlock extends Vue {
    @Prop() startDate!: Date;
    @Prop() endDate!: Date;
    @Prop() size!: undefined | 'is-small';
    @Prop() color!: string;

    get isMultiDay(): boolean {
        // DD-FE-2729 -On the shop page events lasting less than 24 hours but spanning multiple days
        // will be displayed with only the starting date shown (on the DateBlock component),
        // even if the event technically extends over 2 days.

        // difference from event start and end in hours
        return (
            Math.abs(this.endDate.valueOf() - this.startDate.valueOf()) / 36e5 >
            24
        );
    }

    get start(): { day: string; month: string } {
        return {
            day: this.day(this.startDate),
            month: this.month(this.startDate),
        };
    }

    get end(): { day: string; month: string } {
        return {
            day: this.day(this.endDate),
            month: this.month(this.endDate),
        };
    }

    day(date: Date): string {
        return date.getDate().toString();
    }

    month(date: Date): string {
        return this.$localization.locale.monthNamesAbbr[date.getMonth()];
    }
}
