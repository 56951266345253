export type EmptyCollection = Record<never, never> | [];
export type NonEmptyCollection =
    | Record<PropertyKey, unknown>
    | [unknown, ...unknown[]];

export function isEmptyCollection(value: unknown): value is EmptyCollection {
    if (!value || typeof value !== 'object') {
        return false;
    }

    return !Object.keys(value).length;
}

export function isNonEmptyCollection(
    value: unknown
): value is NonEmptyCollection {
    if (!value || typeof value !== 'object') {
        return false;
    }

    return !!Object.keys(value).length;
}
