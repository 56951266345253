var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop-module"},[_c('div',{staticClass:"ot-card timeslots"},[_c('div',{staticClass:"timeslots__calendar"},[_c('DatePicker',{ref:"datepicker",attrs:{"date":_vm.currentDate,"enabled-dates":_vm.enabledDates},on:{"input":function (val) { return _vm.onDateChange(val); }}})],1),_c('Collapse',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"timeslots__filters"},[_c('div',{staticClass:"ot-separator has-label"},[_vm._v(" "+_vm._s(_vm.$t('shop.components.timeslots.select_filter'))+" ")]),_c('div',{staticClass:"timeslots__filters__items"},_vm._l((_vm.eventDates),function(eventDate){return _c('div',{key:eventDate.guid,staticClass:"timeslots__filters__item",class:[
                            {
                                selected:
                                    eventDate.guid ===
                                    _vm.$route.query.eventDate,
                                disabled: !_vm.eventDateTicketCount[
                                    eventDate.guid
                                ],
                            },
                            _vm.getSelectorClass(eventDate) ],on:{"click":function($event){return _vm.setEventDateFilter(eventDate)}}},[_vm._v(" "+_vm._s(_vm.$l.time( eventDate.start, Intl.DateTimeFormat().resolvedOptions() .timeZone ))+" - "+_vm._s(_vm.$l.time( eventDate.end, Intl.DateTimeFormat().resolvedOptions() .timeZone ))+" ")])}),0)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }