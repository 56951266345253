import { render, staticRenderFns } from "./OrderBar.vue?vue&type=template&id=4c4687d5&scoped=true&"
import script from "./OrderBar.vue?vue&type=script&lang=ts&"
export * from "./OrderBar.vue?vue&type=script&lang=ts&"
import style0 from "./OrderBar.vue?vue&type=style&index=0&id=4c4687d5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c4687d5",
  null
  
)

export default component.exports