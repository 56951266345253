










































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import {
    CartItemChildrenCollection,
    CartItemChildType,
    CartProduct,
    PricingDetails,
    Ticket,
} from '@openticket/sdk-shop';

@Component
export default class CartReviewTicketOptionalProduct extends Vue {
    @Prop({ required: true }) ticket!: Ticket;
    @Prop({ required: true }) cartTicketId!: string;
    @Prop({ required: true }) ticketIndex!: number;

    cartTicketProducts: CartItemChildrenCollection<CartProduct> | null = null;

    originalPricing: PricingDetails | null = null;

    ticketListener!: string;

    couponListener!: string;

    ticketOptionalProducts: {
        [productId: string]: {
            name: string;
            originalPricing: PricingDetails;
            total_price: number;
            counts: {
                [price: number]: number;
            };
        };
    } = {};

    created(): void {
        this.ticketListener = this.$shop.cart.on(
            ['ticket', this.cartTicketId],
            () => {
                this.updateTicket();
            }
        );

        this.couponListener = this.$shop.cart.on(['coupon'], () => {
            this.updateTicket();
        });

        this.originalPricing = this.$shop.data.tickets_map[
            this.ticket.guid
        ].original_pricing;

        this.updateTicket();
    }

    updateOptionalTicketProducts(): void {
        if (!this.cartTicketProducts) {
            return;
        }

        this.ticketOptionalProducts = {};
        this.cartTicketProducts.collection
            .filter(p => p.is_optional)
            .forEach((p: CartProduct) => {
                const ticketProductEvent = this.$shop.cart.getTicketProductInfo(
                    this.cartTicketId,
                    p.item.guid
                );

                this.ticketOptionalProducts[p.item.guid] = {
                    name: p.item.name,
                    originalPricing: this.$shop.data.products_map[p.item.guid]
                        .pricing,
                    total_price: ticketProductEvent.pricing.total_price,
                    counts: ticketProductEvent.counts,
                };
            });
    }

    updateTicket(): void {
        try {
            this.cartTicketProducts = {
                ...this.$shop.cart.items[this.ticket.event_id][
                    CartItemChildType.Tickets
                ].map[this.ticket.guid][this.cartTicketId],
            }.products;
            this.updateOptionalTicketProducts();
        } catch (e) {
            this.cartTicketProducts = null;
        }
    }

    destroyed(): void {
        this.$shop.off(this.ticketListener);
        this.$shop.off(this.couponListener);
    }

    protected get ticketFreePriceSlug(): string | null {
        if (this.$te('shop.components.shop_group_item.ticket.item_free')) {
            return 'shop.components.shop_group_item.ticket.item_free';
        }

        return 'shop.components.shop_group_item.item_free';
    }

    protected get ticketPriceSlug(): string | null {
        if (this.$te('shop.components.shop_group_item.ticket.item_price')) {
            return 'shop.components.shop_group_item.ticket.item_price';
        }

        return 'shop.components.shop_group_item.item_price';
    }

    get ticketFeeSlug(): string | null {
        if (this.$te('shop.components.shop_group_item.ticket.item_fee')) {
            return 'shop.components.shop_group_item.ticket.item_fee';
        }

        return 'shop.components.shop_group_item.item_fee';
    }

    protected get totalPriceSlug(): string {
        if (this.$te('shop.components.shop_group_item.ticket.price_total')) {
            return 'shop.components.shop_group_item.ticket.price_total';
        }

        return 'shop.components.shop_group_item.price_total';
    }
}
