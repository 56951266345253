


























































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import ticketswapLogo from '@/assets/images/brands/ticketswap.svg';
import tixelLogo from '@/assets/images/brands/tixel.svg';

interface WaitingListReseller {
    name: string;
    logo: string;
    titleSlug: string;
    descriptionSlug: string;
}

export type WaitingListResellerKey = 'ticketswap' | 'tixel';

@Component
export default class WaitingListResellerInput extends Vue {
    @Prop({ required: true }) reseller!: WaitingListResellerKey;
    @Prop({ required: true }) checked!: boolean;

    waitingListResellers!: Record<WaitingListResellerKey, WaitingListReseller>;

    created(): void {
        this.waitingListResellers = {
            ticketswap: {
                name: 'ticketswap',
                logo: ticketswapLogo,
                titleSlug:
                    'shop.components.waiting_list.details.form.reseller.ticketswap.title',
                descriptionSlug:
                    'shop.components.waiting_list.details.form.reseller.ticketswap.description',
            },
            tixel: {
                name: 'tixel',
                logo: tixelLogo,
                titleSlug:
                    'shop.components.waiting_list.details.form.reseller.tixel.title',
                descriptionSlug:
                    'shop.components.waiting_list.details.form.reseller.tixel.description',
            },
        };
    }

    get waitingListReseller(): WaitingListReseller {
        return this.waitingListResellers[this.reseller];
    }

    onInput() {
        this.$emit('input', !this.checked);
    }
}
