


































import ShopModuleComponent from './module';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ShopProduct } from '@openticket/sdk-shop';
import CollapseCard from '../components/CollapseCard.vue';
import ProductGroupItem from '../components/ProductGroupItem.vue';

@Component({
    components: {
        CollapseCard,
        ProductGroupItem,
    },
})
export default class ProductItem extends ShopModuleComponent {
    /* SHOP MODULE */

    public scopes: string[] = ['products'];

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    @Prop() guid!: string;
    @Prop() eventGuid!: string;

    get product(): ShopProduct {
        let products;
        if (this.eventGuid) {
            products = this.$shop.data.events_map[this.eventGuid]
                .global_products;
        } else {
            products = this.$shop.data.global_products;
        }

        return products.find(
            (product: ShopProduct) => product.guid === this.guid
        ) as ShopProduct;
    }
}
