


























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class OrderBarTotalPart extends Vue {
    @Prop({ default: null, type: String })
    urlSlug!: string | null;

    @Prop({ required: true, type: String })
    labelSlug!: string;
}
