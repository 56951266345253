import { CookieMessages, PartialCookieMessages } from './types';

export const defaultMessages: CookieMessages = {
    configure: {
        cancel: {
            text: 'Cancel',
            title: 'Click to go back to the previous step.',
        },
        confirm: {
            text: 'Save preferences',
            title: 'Click to save preferences.',
        },
        deselectAll: {
            text: 'Deselect all',
            title: 'Click to deselect all non-essential categories.',
        },
        essential: 'Essential',
        selectAll: {
            text: 'Select all',
            title: 'Click to select all cookie categories.',
        },
        title: 'Change cookie preferences',
    },
    error: {
        close: {
            text: 'Close',
            title: 'Close the dialog.',
        },
        description:
            'The application is unable to view or change cookie preferences.',
        title: 'Cookies',
    },
    landing: {
        acceptAll: {
            text: 'Accept cookies',
            title: 'Click to accept all categories.',
        },
        configure: {
            text: 'Change cookie preferences',
            title: 'Click to see and change your cookie preferences.',
        },
        description:
            'We use cookies to improve your experience, like remembering your language preferences and previously entered information.',
        reject: {
            text: 'Decline cookies',
            title: 'Click to decline all cookies.',
        },
        title: 'Cookies',
    },
};

export function mergeMessages(
    target: CookieMessages,
    messages?: PartialCookieMessages
): void {
    target.configure.cancel.text = getValue(
        'text',
        defaultMessages.configure.cancel,
        messages?.configure?.cancel
    );
    target.configure.cancel.title = getValue(
        'title',
        defaultMessages.configure.cancel,
        messages?.configure?.cancel
    );
    target.configure.confirm.text = getValue(
        'text',
        defaultMessages.configure.confirm,
        messages?.configure?.confirm
    );
    target.configure.confirm.title = getValue(
        'title',
        defaultMessages.configure.confirm,
        messages?.configure?.confirm
    );
    target.configure.deselectAll.text = getValue(
        'text',
        defaultMessages.configure.deselectAll,
        messages?.configure?.deselectAll
    );
    target.configure.deselectAll.title = getValue(
        'title',
        defaultMessages.configure.deselectAll,
        messages?.configure?.deselectAll
    );
    target.configure.essential = getValue(
        'essential',
        defaultMessages.configure,
        messages?.configure
    );
    target.configure.selectAll.text = getValue(
        'text',
        defaultMessages.configure.selectAll,
        messages?.configure?.selectAll
    );
    target.configure.selectAll.title = getValue(
        'title',
        defaultMessages.configure.selectAll,
        messages?.configure?.selectAll
    );
    target.configure.title = getValue(
        'title',
        defaultMessages.configure,
        messages?.configure
    );

    target.error.close.text = getValue(
        'text',
        defaultMessages.error.close,
        messages?.error?.close
    );
    target.error.close.title = getValue(
        'title',
        defaultMessages.error.close,
        messages?.error?.close
    );
    target.error.description = getValue(
        'description',
        defaultMessages.error,
        messages?.error
    );
    target.error.title = getValue(
        'title',
        defaultMessages.error,
        messages?.error
    );

    target.landing.acceptAll.text = getValue(
        'text',
        defaultMessages.landing.acceptAll,
        messages?.landing?.acceptAll
    );
    target.landing.acceptAll.title = getValue(
        'title',
        defaultMessages.landing.acceptAll,
        messages?.landing?.acceptAll
    );
    target.landing.configure.text = getValue(
        'text',
        defaultMessages.landing.configure,
        messages?.landing?.configure
    );
    target.landing.configure.title = getValue(
        'title',
        defaultMessages.landing.configure,
        messages?.landing?.configure
    );
    target.landing.description = getValue(
        'description',
        defaultMessages.landing,
        messages?.landing
    );
    target.landing.reject.text = getValue(
        'text',
        defaultMessages.landing.reject,
        messages?.landing?.reject
    );
    target.landing.reject.title = getValue(
        'title',
        defaultMessages.landing.reject,
        messages?.landing?.reject
    );
    target.landing.title = getValue(
        'title',
        defaultMessages.landing,
        messages?.landing
    );
}

export function getValue<Key extends string>(
    key: Key,
    fallback: { [K in Key]: string },
    value?: null | Partial<{ [K in Key]: string }>
): string {
    return value?.[key] || fallback[key];
}
