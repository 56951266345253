















import Component from 'vue-class-component';
import ProductGroupItem from '../components/ProductGroupItem.vue';
import CollapseCard from '../components/CollapseCard.vue';
import { Event, ShopProduct } from '@openticket/sdk-shop';
import ShopModule from './module';

@Component({
    components: {
        ProductGroupItem,
        CollapseCard,
    },
})
export default class ProductsView extends ShopModule {
    /* SHOP MODULE */
    public scopes = ['products'];

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    get event_id(): string | null {
        const event_id = this.$router.currentRoute.query.event;
        return Array.isArray(event_id) ? event_id[0] : event_id;
    }

    get event(): Event | undefined {
        if (this.event_id) {
            return this.$shop.data.events_map[this.event_id];
        }
        return undefined;
    }

    get products(): ShopProduct[] {
        const products = Array.from(this.$shop.data.global_products);

        if (this.event) {
            products.push(...this.event.global_products);
        } else {
            for (const event of this.$shop.data.events) {
                products.push(...event.global_products);
            }
        }

        return products;
    }
}
