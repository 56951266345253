



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BrandedHeader extends Vue {
    @Prop({ type: String })
    title!: string;

    @Prop({ default: false, type: Boolean })
    hideLogo!: boolean;
}
