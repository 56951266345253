import { CookieStorageContract } from './types';

export const localStorageTestKey = '@openticket:test-local-storage' as const;
export const localStoragePrefix = '@openticket:cookieprefs' as const;

export class CookieLocalStorage implements CookieStorageContract {
    enabled: boolean;

    constructor() {
        this.enabled = hasLocalStorage();

        if (!this.enabled) {
            // TODO @openticket/lib-log
            console.info('Cookie preferences storage (localStorage) disabled');
        }
    }

    get(key: string): boolean | null {
        if (!this.enabled) {
            return null;
        }

        try {
            const value = localStorage.getItem(this.getKey(key));

            if (typeof value === 'string') {
                return value === '1';
            }
        } catch (e) {
            // TODO @openticket/lib-log

            console.error('Failed to get cookie preference', key, e);
        }

        return null;
    }

    set(key: string, value: boolean | null): void {
        if (!this.enabled) {
            return;
        }

        try {
            if (typeof value === 'boolean') {
                localStorage.setItem(this.getKey(key), value ? '1' : '0');
            } else {
                localStorage.removeItem(this.getKey(key));
            }
        } catch (e) {
            // TODO @openticket/lib-log

            console.error('Failed to set cookie preference', key, value, e);
        }
    }

    getKey(key: string): string {
        return `${localStoragePrefix}:${key}`;
    }
}

export function hasLocalStorage(): boolean {
    try {
        if (typeof localStorage !== 'undefined') {
            try {
                localStorage.setItem(localStorageTestKey, localStorageTestKey);

                if (
                    localStorage.getItem(localStorageTestKey) ===
                    localStorageTestKey
                ) {
                    return true;
                }
            } finally {
                // Always cleanup this value - If this fails, the wrapping try will catch it.
                localStorage.removeItem(localStorageTestKey);
            }
        }

        return false;
    } catch (e) {
        return false;
    }
}
