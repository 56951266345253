import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex';
import { RootState, ShopPage } from './types';
import router from '../router';

export const SET_PAGES = 'SET_PAGES';

const actions: ActionTree<RootState, RootState> = {
    async nextPage({ state }, query: any) {
        const name = router.currentRoute.params.page;
        const index =
            state.pages.findIndex((page: ShopPage) => page.name === name) + 1;

        query = { ...router.currentRoute.query, ...(query || {}) };

        await router.push({
            name: 'step',
            params: {
                page: state.pages[index].name,
            },
            query,
        });
    },
    async prevPage({ state }) {
        const name = router.currentRoute.params.page;
        const index =
            state.pages.findIndex((page: ShopPage) => page.name === name) - 1;

        await router.push({
            name: 'step',
            params: {
                page: state.pages[index].name,
            },
            query: router.currentRoute.query,
        });
    },
};

export const getters: GetterTree<RootState, RootState> = {};

export const mutations: MutationTree<RootState> = {
    [SET_PAGES](state, pages: ShopPage[]) {
        state.pages = pages;
    },
};

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    state: {
        pages: [],
        dates: {},
        date: '',
    },
    actions,
    getters,
    mutations,
};

export default new Vuex.Store<RootState>(store);
