import Vue, { VueConstructor } from 'vue';
import {
    CookieCategories,
    CookieCategory,
    CookieCategoryMessages,
    CookieListenerChange,
    CookieListeners,
    CookieManagerState,
    CookieMessages,
    CookieMode,
    CookieStorageContract,
    PartialCombinedMessages,
} from './types';
import { defaultMessages, getValue, mergeMessages } from './messages';
import { CookieLocalStorage } from './storage';

export type CookieKeys =
    | 'functional'
    | 'analytical'
    | 'organiserMarketing'
    | 'embeddedContent';

export type CookiePreferences = Record<CookieKeys, boolean>;

export function isValidCookieKey(key: unknown): key is CookieKeys {
    return (
        !!key &&
        typeof key === 'string' &&
        [
            'functional',
            'analytical',
            'organiserMarketing',
            'embeddedContent',
        ].includes(key)
    );
}

export function isCallback(value: unknown): value is CallableFunction {
    return !!value && typeof value === 'function';
}

export const defaultCategoryMessages: Record<
    CookieKeys,
    CookieCategoryMessages
> = {
    functional: {
        description:
            'These cookies are necessary to the core functionality of our website & the organisers’ shop, such as access to secure areas.',
        title: 'Functional cookies',
    },
    analytical: {
        description:
            "These cookies help us gather valuable insights into how visitors use our website and the organisers' shop. This information helps us improve our services and provide you with a better user experience.",
        title: 'Analytical cookies',
    },
    organiserMarketing: {
        description:
            "These cookies enables organisers to tailor advertisements to your interests. By allowing these cookies, you'll receive offers and information that are more relevant to your preferences.",
        title: 'Marketing cookies of organiser',
    },
    embeddedContent: {
        description:
            'These cookies enhance your experience by allowing us to display multimedia elements, such as videos, maps, and social media feeds, directly within our website and the shop',
        title: 'Embedded content',
    },
};

export class CookieManager {
    static pending: Extract<CookieManagerState, 'pending'> = 'pending' as const;
    static incomplete: Extract<
        CookieManagerState,
        'incomplete'
    > = 'incomplete' as const;
    static complete: Extract<
        CookieManagerState,
        'complete'
    > = 'complete' as const;

    static install(
        _Vue: VueConstructor,
        storage?: CookieStorageContract
    ): void {
        const cookieManager = new CookieManager(storage);

        _Vue.mixin({
            provide: {
                cookies: cookieManager,
            },
        });

        Object.defineProperty(_Vue.prototype, '$cookies', {
            get: () => cookieManager,
        });
    }

    readonly categories: CookieCategories<CookieKeys>;
    readonly messages: CookieMessages;
    state: CookieManagerState;
    storage: CookieStorageContract;

    private readonly _mode: CookieMode;
    get mode(): CookieMode {
        return this._mode;
    }

    private readonly _loaded: Promise<CookieManagerState>;
    private resolveLoadedPromise: { (state: CookieManagerState): void };
    get loaded(): Promise<CookieManagerState> {
        return this._loaded;
    }

    private _complete: Promise<void>;
    private resolveCompletePromise: (value: PromiseLike<void> | void) => void;
    get complete(): Promise<void> {
        return this._complete;
    }

    private listeners: CookieListeners<CookieKeys> = {
        change: new Set(),
    };
    private previousComparableState: string;

    get functional(): CookieCategory {
        return this.categories.functional;
    }

    get analytical(): CookieCategory {
        return this.categories.analytical;
    }

    get embeddedContent(): CookieCategory {
        return this.categories.embeddedContent;
    }

    get organiserMarketing(): CookieCategory {
        return this.categories.organiserMarketing;
    }

    constructor(storage?: CookieStorageContract) {
        this.resolveLoadedPromise = (): void => {
            // TODO @openticket/lib-log Log calling resolve fn before promise initialiser.
        };
        this.resolveCompletePromise = (): void => {
            // TODO @openticket/lib-log Log calling resolve fn before promise initialiser.
        };

        this._loaded = new Promise(resolve => {
            this.resolveLoadedPromise = resolve;
        });

        this._complete = new Promise(resolve => {
            this.resolveCompletePromise = resolve;
        });

        this.categories = Vue.observable({
            functional: {
                // Auto fill shop (metadata), Preferred locale
                // Cookie preferences: Will always be saved for now.
                ...defaultCategoryMessages.functional,
                confirmed: true,
                value: true,
                readonly: true,
            },
            analytical: {
                // Eventix GTM, Rudderstack
                ...defaultCategoryMessages.analytical,
                confirmed: true,
                value: true,
                readonly: true,
            },
            organiserMarketing: {
                // Organiser GTM
                ...defaultCategoryMessages.organiserMarketing,
                confirmed: false,
                value: false,
                readonly: false,
            },
            embeddedContent: {
                // Best-effort basis external content. i.e.: Facebook header
                ...defaultCategoryMessages.embeddedContent,
                confirmed: false,
                value: false,
                readonly: false,
            },
        } as const);
        this.previousComparableState = this.getComparableState();

        this.messages = Vue.observable(defaultMessages);
        this.state = CookieManager.pending;
        this.storage = storage || new CookieLocalStorage();

        this._mode = Vue.observable({
            strict: new URLSearchParams(window.location.search).has('strict'),
        });
    }

    async load(): Promise<CookieManagerState> {
        try {
            const allConfirmed = Object.entries<CookieCategory>(
                this.categories
            ).reduce(
                (
                    carry: boolean,
                    [key, category]: [string, CookieCategory]
                ): boolean => {
                    if (category.readonly) {
                        return carry;
                    }

                    const value = this.storage.get(key);

                    if (typeof value === 'boolean') {
                        category.value = value;
                        category.confirmed = true;
                    }

                    return carry && category.confirmed;
                },
                true
            );

            if (allConfirmed) {
                this.state = CookieManager.complete;

                this.resolveCompletePromise();
            } else {
                this.state = CookieManager.incomplete;
                // No need to reset the complete promise.
                // This method can not 'invalidate' earlier complete choices..
            }
        } catch (e) {
            // TODO @openticket/lib-log
            this.state = CookieManager.incomplete;

            console.error('Failed to load cookie preferences', e);
        } finally {
            this.resolveLoadedPromise(this.state);

            this.emitWhenChanged();
        }

        return this.loaded;
    }

    manual(
        values: Partial<Record<CookieKeys, boolean>>,
        strict?: boolean
    ): CookieManagerState {
        try {
            if (strict !== undefined && (strict as unknown) !== false) {
                this._mode.strict = true;
            }

            if (
                !values ||
                typeof values !== 'object' ||
                Array.isArray(values)
            ) {
                return this.state;
            }

            try {
                const allConfirmed = Object.entries<CookieCategory>(
                    this.categories
                ).reduce(
                    (
                        carry: boolean,
                        [key, category]: [string, CookieCategory]
                    ): boolean => {
                        if (category.confirmed || category.readonly) {
                            // DD-FE-272D - The manual method should not be destructive over loaded values.
                            return carry;
                        }

                        const value: unknown = (values as Record<
                            string,
                            unknown
                        >)[key];

                        if (typeof value === 'boolean') {
                            category.value = value;
                            category.confirmed = true;
                        }

                        return carry && category.confirmed;
                    },
                    true
                );

                if (allConfirmed) {
                    this.state = CookieManager.complete;

                    this.resolveCompletePromise();
                } else {
                    this.state = CookieManager.incomplete;
                    // No need to reset the complete promise.
                    // This method can not 'invalidate' earlier complete choices..
                }
            } catch (e) {
                // TODO @openticket/lib-log
                console.error('Failed to manually set cookie preferences', e);

                this.state = CookieManager.incomplete;
            }
        } finally {
            // This should have no impact on the 'loaded' promise.
            // If nothing is loaded, there is no need to await it.
            // The only reason for the loaded promise to be awaited is after load is called.
            // In that case, even if the state is technically complete here,
            // The in-progress loading operation could still have a different result, which should take precedent.

            this.emitWhenChanged();
        }

        return this.state;
    }

    acceptAll(): void {
        try {
            Object.entries(this.categories).forEach(
                ([key, category]: [string, CookieCategory]) => {
                    if (category.readonly) {
                        return;
                    }

                    category.value = true;
                    category.confirmed = true;

                    this.storage.set(key, true);
                }
            );

            this.state = CookieManager.complete;

            this.resolveCompletePromise();
        } finally {
            this.emitWhenChanged();
        }
    }

    confirm(): void {
        try {
            Object.entries(this.categories).forEach(
                ([key, category]: [string, CookieCategory]) => {
                    if (category.readonly) {
                        return;
                    }

                    category.confirmed = true;

                    this.storage.set(key, category.value);
                }
            );

            this.state = CookieManager.complete;

            this.resolveCompletePromise();
        } finally {
            this.emitWhenChanged();
        }
    }

    reject(): void {
        try {
            Object.entries(this.categories).forEach(
                ([key, category]: [string, CookieCategory]) => {
                    if (category.readonly) {
                        return;
                    }

                    category.value = false;
                    category.confirmed = true;

                    this.storage.set(key, false);
                }
            );

            this.state = CookieManager.complete;

            this.resolveCompletePromise();
        } finally {
            this.emitWhenChanged();
        }
    }

    clear(): void {
        try {
            Object.entries(this.categories).forEach(
                ([key, category]: [string, CookieCategory]) => {
                    if (category.readonly) {
                        return;
                    }

                    category.value = false;
                    category.confirmed = false;

                    this.storage.set(key, null);
                }
            );

            if (this.state === CookieManager.complete) {
                // Only a complete state needs to be reset to incomplete.
                // In case of a pending state, this is irrelevant.
                this.state = CookieManager.incomplete;

                // As the state goes from complete to incomplete,
                // the complete promise should be reset for any future use.
                // The loading promise does NOT need to be reset,
                // as there is no way - specifically for this session -
                // for the values to be unanticipated.
                this._complete = new Promise(resolve => {
                    this.resolveCompletePromise = resolve;
                });
            }
        } finally {
            this.emitWhenChanged();
        }
    }

    selectAll(): void {
        try {
            Object.values(this.categories).forEach(
                (category: CookieCategory) => {
                    if (category.readonly) {
                        return;
                    }

                    category.value = true;
                }
            );
        } finally {
            this.emitWhenChanged();
        }
    }

    deselectAll(): void {
        try {
            Object.values(this.categories).forEach(
                (category: CookieCategory) => {
                    if (category.readonly) {
                        return;
                    }

                    category.value = false;
                }
            );
        } finally {
            this.emitWhenChanged();
        }
    }

    setMessages(messages?: null | PartialCombinedMessages<CookieKeys>): void {
        if (!messages) {
            return;
        }

        mergeMessages(this.messages, messages);

        this.categories.functional.description = getValue(
            'description',
            this.categories.functional,
            messages.categories?.functional
        );
        this.categories.functional.title = getValue(
            'title',
            this.categories.functional,
            messages.categories?.functional
        );

        this.categories.analytical.description = getValue(
            'description',
            this.categories.analytical,
            messages.categories?.analytical
        );
        this.categories.analytical.title = getValue(
            'title',
            this.categories.analytical,
            messages.categories?.analytical
        );

        this.categories.embeddedContent.description = getValue(
            'description',
            this.categories.embeddedContent,
            messages.categories?.embeddedContent
        );
        this.categories.embeddedContent.title = getValue(
            'title',
            this.categories.embeddedContent,
            messages.categories?.embeddedContent
        );

        this.categories.organiserMarketing.description = getValue(
            'description',
            this.categories.organiserMarketing,
            messages.categories?.organiserMarketing
        );
        this.categories.organiserMarketing.title = getValue(
            'title',
            this.categories.organiserMarketing,
            messages.categories?.organiserMarketing
        );
    }

    // This will return false as a value if the value was not (yet) confirmed.
    // Loaded values from storage and manually added values (e.g. from the host app) are deemed confirmed.
    getSafePreferences(): Partial<CookiePreferences> {
        const safeValues: Partial<CookiePreferences> = {};

        Object.entries(this.categories).forEach(
            ([key, category]: [string, CookieCategory]) => {
                if (isValidCookieKey(key)) {
                    safeValues[key] = category.confirmed && category.value;
                }
            }
        );

        return safeValues;
    }

    onChange(cb: CookieListenerChange<CookieKeys>): () => void {
        if (!isCallback(cb)) {
            throw new Error(
                'Invalid onChange callback. Callback should be a CallableFunction.'
            );
        }

        const uniqueCb: CookieListenerChange<CookieKeys> = cb.bind(this);

        this.listeners.change.add(uniqueCb);

        return () => {
            this.listeners.change.delete(uniqueCb);
        };
    }

    // The comparable state is an easily comparable data structure which includes
    // all category values and confirmed state.
    getComparableState(): string {
        return Object.entries(this.categories)
            .map(([key, category]) => {
                return [
                    key,
                    category.value ? 'v1' : 'v0',
                    category.confirmed ? 'c1' : 'c0',
                ].join(':');
            })
            .join('|');
    }

    private emitWhenChanged(): void {
        const newComparableState: string = this.getComparableState();

        if (this.previousComparableState !== newComparableState) {
            this.previousComparableState = newComparableState;
            const safeValues: Partial<CookiePreferences> = this.getSafePreferences();
            const complete: boolean = this.state === CookieManager.complete;

            for (const cb of this.listeners.change) {
                cb.call(this, safeValues, complete);
            }
        }
    }
}
