
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Event } from '@openticket/sdk-shop';
import { getSelectorClass } from '@/utils';
import EventSummaryHeader from '@/pages/shop/components/EventSummaryHeader.vue';

@Component({
    components: {
        EventSummaryHeader,
    },
})
export default class EventCard extends Vue {
    @Prop() event!: Event;

    private get eventSelectorClass(): string {
        return getSelectorClass('event', this.event.guid);
    }
}
