var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ot-card ticket-collapse has-no-padding",class:{
        'ticket-collapse--opened': _vm.opened,
        'ticket-collapse--done': _vm.complete,
        'ticket-collapse--focus': _vm.opened,
    },attrs:{"id":("ticket-" + (_vm.ticket.reservation.reservation))}},[_c('div',{staticClass:"ticket-collapse__header",on:{"click":function($event){return _vm.$emit('toggle')}}},[_c('div',{staticClass:"ticket-collapse__header__container ot-clickable"},[_c('h6',[_vm._v(" "+_vm._s(_vm.$t('shop.components.ticket_metadata_group.title', { index: _vm.index + 1, name: _vm.ticket.item.name, }))+" ")]),_c('div',{staticClass:"ticket-collapse__header__action"},[(!_vm.opened)?_c('i',{staticClass:"oti oti-drop-down"}):_c('i',{staticClass:"oti oti-drop-up"})])])]),_c('Collapse',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.opened),expression:"opened"}],staticClass:"ticket-collapse__form"},[_c('div',{staticClass:"ticket-collapse__form__container"},[_c('Collapse',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                            _vm.overlapsWithBookerInformation &&
                                !_vm.hasAutofilled &&
                                _vm.index === 0
                        ),expression:"\n                            overlapsWithBookerInformation &&\n                                !hasAutofilled &&\n                                index === 0\n                        "}],on:{"click":_vm.autofill}},[_c('div',{staticClass:"ticket-collapse__form__autofill"},[_c('span',{staticClass:"text ot-text-small"},[_vm._v(_vm._s(_vm.$t( 'shop.components.ticket_metadata_group.autofill.label' )))]),_c('button',{staticClass:"ot-button is-tiny is-dark"},[_vm._v(" "+_vm._s(_vm.$t( 'shop.components.ticket_metadata_group.autofill.button' ))+" ")])])])]),_vm._l((_vm.metadata),function(meta){return _c('MetaInput',{key:meta.item.guid,class:{
                        highlight: _vm.autofilled.includes(meta.item.guid),
                    },attrs:{"meta":meta},on:{"input":_vm.input}})}),_vm._l((_vm.productGroups),function(productGroup,index){return _c('OptionalProductGroup',{key:((_vm.ticket.id) + "_" + (productGroup.guid)),attrs:{"ticket":_vm.ticket,"product-group":productGroup,"even-or-odd":!!(index % 2)},on:{"input":_vm.input,"product":_vm.product}})}),(_vm.showNext)?_c('div',{staticClass:"ticket-collapse__form__next"},[_c('button',{staticClass:"ot-button is-fullwidth",attrs:{"disabled":!_vm.complete},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t('shop.components.ticket_metadata_group.next'))+" ")])]):_vm._e()],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }