


























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import WaitingListInfo from '@/pages/shop/components/WaitingListInfo.vue';
import { Event } from '@openticket/sdk-shop';
import EventSummaryHeader from '@/pages/shop/components/EventSummaryHeader.vue';
import Terms from '@/pages/shop/modules/Terms.vue';
import ShopModuleComponent from '@/pages/shop/modules/module';
import { scrollToOffset } from '@/utils';
import { WaitingListModel } from '@/utils/waitingList';

@Component({
    components: { Terms, EventSummaryHeader, WaitingListInfo },
})
export default class WaitingListDetails extends Vue {
    @Prop() event!: Event;

    @Prop({ required: true }) waitingList!: WaitingListModel;

    private modules: Array<string> = ['waiting_list_info', 'terms'];

    getModules(): ShopModuleComponent[] {
        const components: any[] = [];

        for (const module of this.modules) {
            const elem = this.$refs[`shop_module_${module}`];

            if (Array.isArray(elem)) {
                components.push(elem[0]);
            } else {
                components.push(elem);
            }
        }

        return components;
    }

    async submit(): Promise<void> {
        const modules = this.getModules();

        // Check for validation errors in the modules, prior to submitting
        for (let i = 0; i < modules.length; i++) {
            const module = modules[i];

            // Check for validation errors
            let error = module.validate(true);

            if (error) {
                const message = error.slug
                    ? (this.$t(error.slug, error.slugData) as string)
                    : error.message;

                // Send notifications
                this.$shop.notifications.send({
                    message: message,
                    type: 'is-danger',
                });

                if (module) {
                    // Highlight the module
                    const cls = 'shop-module-focus';
                    const regex = new RegExp('(\\s|^)' + cls + '(\\s|$)');

                    const element = module.$el;

                    if (element) {
                        const offset =
                            module.$el.getBoundingClientRect().top +
                            window.scrollY;

                        scrollToOffset(Math.max(offset - 80, 0));

                        element.className += ' ' + cls;

                        setTimeout(() => {
                            element.className = element.className.replace(
                                regex,
                                ' '
                            );
                        }, 750);
                    }
                }

                return;
            }
        }

        // Submitting the information of the waiting list info
        const waitingListInfo = this.$refs[
            `shop_module_waiting_list_info`
        ] as WaitingListInfo;
        const submitted: boolean = await waitingListInfo.submit();

        // If there are no validation errors and the waiting list info is submitted
        //  then go to the next page, otherwise show an error message
        if (submitted) {
            this.nextPage();
        } else {
            // Send notifications
            this.$shop.notifications.send({
                message: 'Something went wrong. Please try again later.',
                type: 'is-danger',
            });
        }
    }

    nextPage(): void {
        this.$emit('submitted');
    }
}
