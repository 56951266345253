





















import Component from 'vue-class-component';
import EventCard from '../components/EventCard.vue';
import { Event } from '@openticket/sdk-shop';
import { Action } from 'vuex-class';
import ShopModuleComponent, { ShopModuleNotReady } from './module';
import { filterShopContentEvents } from '@/utils/contentFilters';
@Component({
    components: {
        EventCard,
    },
})
export default class EventsView extends ShopModuleComponent {
    @Action('nextPage') nextPage!: (query: any) => void;

    public static isReady(): ShopModuleNotReady | null {
        return null;
    }

    get events(): Event[] {
        return filterShopContentEvents(
            this.shopContentModifiers,
            Object.values(this.$shop.data.events)
        );
    }

    addEventFilter(guid: string): void {
        this.nextPage({
            event: guid,
        });
    }
}
