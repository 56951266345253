import { OpenTicketShop } from '@openticket/sdk-shop';
import {
    CookieConfig,
    ClientWithCookiePreferences,
    ClientWithStrictMode,
    PopupOrEmbedClient,
} from './types';
import {
    CookieKeys,
    CookieManager,
    isValidCookieKey,
    PartialCombinedMessages,
} from '../../composables/cookies';

export function getCookieMessages(
    vm: Vue
): null | PartialCombinedMessages<CookieKeys> {
    if (!vm.$te('common.cookie_modal')) {
        return null;
    }

    return vm.$t('common.cookie_modal') as PartialCombinedMessages<CookieKeys>;
}

export async function initCookies(
    shop: OpenTicketShop,
    manager: CookieManager,
    messages: null | PartialCombinedMessages<CookieKeys>
): Promise<void> {
    manager.setMessages(messages);

    await shop.initialized;

    const {
        cookiePreferences,
        strict,
    }: CookieConfig = await waitForCookieConfig(shop);

    if (cookiePreferences || strict) {
        manager.manual(cookiePreferences || {}, strict);
    }

    await manager.load();

    await manager.complete;
}

export async function waitForCookieConfig(
    shop: OpenTicketShop
): Promise<CookieConfig> {
    const result: CookieConfig = {};

    if (window.self !== window.top && shop.messaging) {
        try {
            await withTimeout(shop.messaging.connecting, 3000);
        } catch (_e) {
            // Ignore, could not find messaging client's preferences
        }

        if (clientIsPopupOrEmbed(shop.messaging.client)) {
            if (clientHasCookiePreferences(shop.messaging.client)) {
                result.cookiePreferences = {};

                Object.entries(shop.messaging.client.cookiePreferences).forEach(
                    ([key, value]) => {
                        if (
                            isValidCookieKey(key) &&
                            typeof value === 'boolean'
                        ) {
                            result.cookiePreferences![key] = value;
                        }
                    }
                );
            }

            if (clientHasStrictMode(shop.messaging.client)) {
                result.strict = shop.messaging.client.strict;
            }
        }
    }

    return result;
}

export function withTimeout<T>(
    promise: PromiseLike<T>,
    ms: number
): Promise<Awaited<T>> {
    return Promise.race<T>([
        promise,
        new Promise((_resolve, reject) => window.setTimeout(reject, ms)),
    ]);
}

function clientIsPopupOrEmbed(client: unknown): client is PopupOrEmbedClient {
    return (
        hasProperty(client, 'type') &&
        (client.type === 'popup' || client.type === 'embed')
    );
}

function clientHasCookiePreferences(
    client: PopupOrEmbedClient
): client is ClientWithCookiePreferences {
    return (
        hasProperty(client, 'cookiePreferences') &&
        !!client.cookiePreferences &&
        typeof client.cookiePreferences === 'object' &&
        !Array.isArray(client.cookiePreferences)
    );
}

function clientHasStrictMode(
    client: PopupOrEmbedClient
): client is ClientWithStrictMode {
    return hasProperty(client, 'strict') && typeof client.strict === 'boolean';
}

function hasProperty<K extends PropertyKey>(
    value: unknown,
    key: K
): value is Record<K, unknown> {
    return (
        !!value &&
        typeof value === 'object' &&
        !Array.isArray(value) &&
        key in value
    );
}
