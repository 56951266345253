

























import ShopModuleComponent from './module';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Paragraph extends ShopModuleComponent {
    /* SHOP MODULE */

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    @Prop() text!: string;

    @Prop({
        default: 'center',
    })
    alignment!: 'left' | 'right' | 'center';
}
