




















































import ShopModuleComponent from './module';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ProductGroup } from '@openticket/sdk-shop';
import CollapseCard from '../components/CollapseCard.vue';
import ProductGroupItem from '../components/ProductGroupItem.vue';
import OptionalItem from '../components/OptionalProductGroup/OptionalItem.vue';
import { findMatch } from '@/utils';
import { CartTicket } from '@openticket/sdk-shop';

@Component({
    components: {
        CollapseCard,
        ProductGroupItem,
        OptionalItem,
    },
})
export default class ProductGroupComponent extends ShopModuleComponent {
    /* SHOP MODULE */

    public scopes: string[] = ['products'];

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    @Prop() match!: any;

    getProductGroup(ticket_guid: string): ProductGroup | undefined {
        const ticket = this.$shop.data.tickets_map[ticket_guid];

        const item = findMatch(ticket.groups, [this.match]);

        if (!item) {
            throw Error(`No product group found that matched ${this.match}`);
        }

        return item;
    }

    get cartTickets(): CartTicket[] {
        return Object.values(this.$shop.cart.flatItems.tickets);
    }

    async add(ticket: string, product: string): Promise<void> {
        await this.$shop.cart.addProductToTicket(ticket, product);
    }

    async remove(ticket: string, product: string): Promise<void> {
        await this.$shop.cart.removeProductFromTicket(ticket, product);
    }
}
