import { OpenTicketShop } from '@openticket/sdk-shop';

import { Listener } from '@/pages/shop/listeners/listener';
import { ListenerForceCartItem } from '@/pages/shop/listeners/listenerForceCartItem';
import { CustomShopSettingsDynamicListener } from '@openticket/lib-custom-shop-settings';
import { ShopModuleShopContentModifiers } from '@/pages/shop/modules/module';

export class ListenerFactory {
    listeners: Array<Listener> = [];
    constructor(
        dynamicListeners: CustomShopSettingsDynamicListener[],
        shop: OpenTicketShop,
        shopContentModifiers: { state: ShopModuleShopContentModifiers | null }
    ) {
        dynamicListeners.forEach(listener => {
            switch (listener.name) {
                case ListenerForceCartItem.ownName:
                    this.listeners.push(
                        new ListenerForceCartItem(
                            shop,
                            listener,
                            shopContentModifiers
                        )
                    );
                    break;
                default: {
                    // @todo: add warning to logger
                    console.warn(
                        `Unknown listener found in dynamic settings (${listener.name}), ignoring`
                    );
                }
            }
        });
    }

    destroy(): void {
        this.listeners.forEach(listener => {
            listener.destroy();
        });
    }
}
