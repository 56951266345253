var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.signup)?_c('div',{staticClass:"waiting-list-info"},[_c('div',{staticClass:"waiting-list-info__form ot-card"},[_c('ot-input-field',{attrs:{"label":_vm.$t(
                    'shop.components.waiting_list.details.form.first_name.label'
                ),"required":true,"error":_vm.errors.first_name}},[_c('ot-input',{attrs:{"type":"text","invalid":!!_vm.errors.first_name},on:{"input":function($event){delete _vm.errors['first_name']}},model:{value:(_vm.signup.first_name),callback:function ($$v) {_vm.$set(_vm.signup, "first_name", $$v)},expression:"signup.first_name"}})],1),_c('ot-input-field',{attrs:{"label":_vm.$t(
                    'shop.components.waiting_list.details.form.last_name.label'
                ),"required":true,"error":_vm.errors.last_name}},[_c('ot-input',{attrs:{"type":"text","invalid":!!_vm.errors.last_name},on:{"input":function($event){delete _vm.errors['last_name']}},model:{value:(_vm.signup.last_name),callback:function ($$v) {_vm.$set(_vm.signup, "last_name", $$v)},expression:"signup.last_name"}})],1),_c('ot-input-field',{attrs:{"label":_vm.$t('shop.components.waiting_list.details.form.email.label'),"required":true,"error":_vm.errors.email}},[_c('ot-input',{attrs:{"type":"text","invalid":!!_vm.errors.email},on:{"input":function($event){delete _vm.errors['email']}},model:{value:(_vm.signup.email),callback:function ($$v) {_vm.$set(_vm.signup, "email", $$v)},expression:"signup.email"}})],1),(_vm.allow_quantity)?_c('ot-input-field',{attrs:{"label":_vm.$t(
                    'shop.components.waiting_list.details.form.quantity.label'
                ),"required":true,"error":_vm.errors.quantity}},[_c('ot-input',{attrs:{"type":"integer","invalid":!!_vm.errors.quantity},on:{"input":_vm.quantityInput},model:{value:(_vm.signup.quantity),callback:function ($$v) {_vm.$set(_vm.signup, "quantity", $$v)},expression:"signup.quantity"}})],1):_vm._e(),_c('ot-input-field',{staticClass:"waiting-list-info__allow-marketing-input",attrs:{"error":_vm.errors.allow_marketing}},[_c('ot-input',{attrs:{"label":_vm.$t(
                        'shop.components.waiting_list.details.form.allow_marketing.label'
                    ),"type":"checkbox","invalid":!!_vm.errors.allow_marketing},on:{"input":function($event){delete _vm.errors['allow_marketing']}},model:{value:(_vm.signup.allow_marketing),callback:function ($$v) {_vm.$set(_vm.signup, "allow_marketing", $$v)},expression:"signup.allow_marketing"}})],1)],1),_c('div',{staticClass:"waiting-list-info__reseller-options"},[(_vm.allowResellerTicketswap)?_c('WaitingListResellerInput',{attrs:{"reseller":"ticketswap","checked":_vm.signup.reseller_ticketswap_enabled},on:{"input":_vm.handleResellerTicketswap}}):_vm._e()],1),_c('div',{staticClass:"waiting-list-info__reseller-options"},[(_vm.allowResellerTixel)?_c('WaitingListResellerInput',{attrs:{"reseller":"tixel","checked":_vm.signup.reseller_tixel_enabled},on:{"input":_vm.handleResellerTixel}}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }