import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import ShopView from './Shop.vue';
import PagesView from './views/Pages.vue';
import ClosedView from './views/Closed.vue';
import ErrorView from '@/pages/Error.vue';
import WaitingListView from '@/pages/shop/views/WaitingList.vue';
import { WAITING_LIST_ROUTE_NAME } from '@/utils/waitingList';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: {
            name: 'error',
        },
    },
    {
        path: '/closed',
        name: 'closed',
        component: ClosedView,
    },
    {
        path: '/error',
        name: 'error',
        component: ErrorView,
    },
    {
        path: '/:shop_id',
        name: 'shop',
        component: ShopView,
        children: [
            {
                path: 'waitinglist',
                name: WAITING_LIST_ROUTE_NAME,
                component: WaitingListView,
            },
            {
                path: ':page?',
                name: 'step',
                component: PagesView,
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.name === from.name && to.params.page === from.params.page) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
});

export default router;
