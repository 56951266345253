




























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CartTicket, ProductGroup } from '@openticket/sdk-shop';
import { getProductPrice } from './pricing';

interface RadioGroupOption {
    label: string;
    description: string;
    suffix: string;
}

@Component
export default class OptionalProductGroupRadioItem extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() productGroup!: ProductGroup;

    oldProductGuid: string | null = null;
    selected: string | null = null;
    options: { [key: string]: RadioGroupOption } = {};

    public created(): void {
        this.update();

        this.oldProductGuid = this.selected;

        this.$shop.cart.on(['ticket', this.ticket.id, 'product'], () => {
            this.update();
        });
    }

    update(): void {
        this.updateSelected();
        this.updateOptions();
    }

    @Watch('ticket.item', { deep: true, immediate: true })
    updateOptions(): void {
        const map: { [key: string]: RadioGroupOption } = {};
        for (const product of this.productGroup.products) {
            map[product.guid] = {
                label: product.item.name,
                description: product.item.description,
                suffix: this.$l.currency(
                    getProductPrice(product, this.ticket),
                    this.$shop.data.currency as string
                ),
            };
        }

        this.options = map;
    }

    select(value: string): void {
        if (this.selected === value && this.productGroup.min_bound === 0) {
            this.selected = null;
            return;
        }

        this.selected = value;
    }

    updateSelected(): void {
        for (const product of this.productGroup.products) {
            for (const cartProduct of this.ticket.products.collection) {
                if (product.guid === cartProduct.item.guid) {
                    this.selected = product.guid;
                    return;
                }
            }
        }
    }

    @Watch('selected')
    onChange(guid: string): void {
        if (this.oldProductGuid !== guid) {
            if (!guid) {
                if (this.oldProductGuid) {
                    this.$emit('remove', this.oldProductGuid);
                }
            } else {
                this.$emit('add', guid, this.oldProductGuid);
            }

            this.oldProductGuid = guid;
        }
    }
}
