












































































import { InitError } from '@openticket/sdk-shop';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ClosedView extends Vue {
    @Prop({ default: true, type: Boolean })
    showPoweredBy!: boolean;

    @Prop({ default: true, type: Boolean })
    showLocaleSwitch!: boolean;

    showErrors = false;

    async created(): Promise<void> {
        if (!this.$shop.messaging) {
            return;
        }

        await this.$shop.messaging.connecting;

        const isRecord = (obj: unknown): obj is Record<string, unknown> =>
            typeof obj === 'object';

        this.showErrors =
            !!this.$shop.messaging.client &&
            isRecord(this.$shop.messaging.client) &&
            this.$shop.messaging.client['type'] === 'dashboard';
    }

    get errors(): InitError[] {
        return this.$shop.initErrors;
    }
}
