import { ShopDates } from '@/pages/shop/store/types';
import dateFormat from 'dateformat';

export function containsDate(dates: ShopDates, date: Date): boolean {
    const dateStr = dateFormat(date, 'yyyy-mm-dd').split('-');

    let root = dates;
    while (root) {
        const key = dateStr.shift() as string;

        const val = root[key];

        if (val) {
            if (Array.isArray(val)) {
                return true;
            } else {
                root = val;
            }
        } else {
            return false;
        }
    }

    return false;
}

export function getFirstDateFromNow(dates: ShopDates): Date {
    const arr = [];

    const now = dateFormat(new Date(), 'yyyy-mm-dd').split('-');

    let root: any = dates;
    let target = now.shift() as string;

    while (root && target) {
        let found = false;

        for (const key of Object.getOwnPropertyNames(root).sort()) {
            if (target === key) {
                root = root[key];
                target = now.shift() as string;

                arr.push(key);

                found = true;
                break;
            }

            if (target < key) {
                arr.push(key);

                let tmpRoot = root[key];
                while (tmpRoot) {
                    const firstKey = Object.getOwnPropertyNames(
                        tmpRoot
                    ).sort()[0];

                    if (!firstKey) {
                        break;
                    }

                    arr.push(firstKey);
                    tmpRoot = tmpRoot[firstKey];
                }

                found = true;
                root = null;
                break;
            }
        }

        if (!found) {
            break;
        }
    }

    return new Date(arr.join('-'));
}
