const localStorageTestKey = '@openticket:test-local-storage' as const;

export function hasLocalStorage(): boolean {
    try {
        if (typeof localStorage !== 'undefined') {
            try {
                localStorage.setItem(localStorageTestKey, localStorageTestKey);

                if (
                    localStorage.getItem(localStorageTestKey) ===
                    localStorageTestKey
                ) {
                    return true;
                }
            } finally {
                // Always cleanup this value
                localStorage.removeItem(localStorageTestKey);
            }
        }

        return false;
    } catch (e) {
        return false;
    }
}
