










































import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';

@Component
export default class TimeoutModal extends Vue {
    @Ref('timeout-dialog')
    dialog!: HTMLDialogElement;

    mounted() {
        this.dialog.showModal();
    }
}
