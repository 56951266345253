




















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import OtLocaleFlag from '@openticket/vue-localization/src/components/OtLocaleFlag.vue';
import OtLocaleSwitch from '@openticket/vue-localization/src/components/OtLocaleSwitch.vue';

@Component({
    components: {
        OtLocaleFlag,
        OtLocaleSwitch,
    },
})
export default class Footer extends Vue {
    @Prop({ type: Boolean })
    dark!: boolean;

    @Prop({ default: true, type: Boolean })
    showPoweredBy!: boolean;

    @Prop({ default: true, type: Boolean })
    showLocaleSwitch!: boolean;

    @Prop({ default: false, type: Boolean })
    hideCookieLink!: boolean;

    @Ref('ot-locale-switch')
    otLocaleSwitch!: OtLocaleSwitch;

    localeSwitchOpen = false;

    get whitelabelWebsiteUrl(): string {
        return this.$whitelabel.website_url;
    }
}
