












































































































































































import { Event, Ticket } from '@openticket/sdk-shop';
import Component from 'vue-class-component';
import EventGroupItemTicket from '@/pages/shop/components/EventGroupItemTicket.vue';
import { Prop } from 'vue-property-decorator';
import ShopModuleComponent, { ShopModuleValidationError } from '../../module';

const LANGUAGES: {
    [key: string]: string;
} = {
    EN: 'English',
    NL: 'Nederlands',
    FR: 'Francais',
};

@Component({
    components: {
        EventGroupItemTicket,
    },
})
export default class SwinkelsGroupTickets extends ShopModuleComponent {
    @Prop() languageSelect!: boolean;
    @Prop() languageOptions!: string[];
    @Prop() languageDefault!: string;

    events: Event[] = [];
    selectedLanguage: string | null = null;
    selectedTime: string | null = null;

    public scopes = ['tickets'];

    public static isReady(): null {
        return null;
    }

    public validate(): ShopModuleValidationError | null {
        if (Object.keys(this.$shop.cart.flatItems.tickets).length > 0) {
            return null;
        }

        return {
            module: 'tickets',
            message: 'No tickets selected',
        };
    }

    created(): void {
        this.events = this.$shop.data.events;

        if (this.languageSelect) {
            this.selectedLanguage = this.languageDefault;
        }

        this.selectedTime = this.filters[0] || null;

        this.$shop.on('update', async () => {
            this.events = this.$shop.data.events;
            this.selectedTime = this.filters[0] || null;

            await this.$shop.cart.removeAllItems();
        });
    }

    get languages(): { [key: string]: string } {
        const map: { [key: string]: string } = {};
        for (const language of this.languageOptions) {
            if (LANGUAGES[language]) {
                map[language] = LANGUAGES[language];
            }
        }
        return map;
    }

    get filters(): string[] {
        if (this.languageSelect && !this.selectedLanguage) {
            return [];
        }

        let tickets = this.allTickets;

        if (this.languageSelect && this.selectedLanguage) {
            tickets = this.getTicketsWith(tickets, this.selectedLanguage);
        }

        const _filters: string[] = [];
        for (const ticket of tickets) {
            const filter = ticket.name
                .split(':')
                .slice(1)
                .join(':')
                .split('-')[0]
                .trim();

            if (_filters.includes(filter)) {
                continue;
            }

            if (!this.getTicketsWith(tickets, filter).length) {
                continue;
            }

            _filters.push(filter);
        }
        return _filters;
    }

    selectTime(time: string): void {
        this.selectedTime = time;
    }

    selectLanguage(language: string): void {
        this.selectedLanguage = language;
        this.selectedTime = this.filters[0];
    }

    getTicketsWith(tickets: Ticket[], val: string): Ticket[] {
        return tickets.filter((ticket: Ticket) => ticket.name.includes(val));
    }

    get allTickets(): Ticket[] {
        const _tickets: Ticket[] = [];
        for (const event of this.events) {
            _tickets.push(...event.tickets);
        }
        return _tickets;
    }

    get tickets(): Ticket[] {
        let tickets: Ticket[] = [];

        if (
            !this.selectedTime ||
            (this.languageSelect && !this.selectedLanguage)
        ) {
            return tickets;
        }

        tickets = this.allTickets;

        if (this.languageSelect) {
            tickets = this.getTicketsWith(
                tickets,
                `- ${this.selectedLanguage}`
            );
        }

        tickets = this.getTicketsWith(tickets, this.selectedTime);

        return tickets;
    }
}
