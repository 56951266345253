






































































































































































































import ShopModule from './module';
import Component from 'vue-class-component';
import OrderBarTotal from '@/pages/shop/components/OrderBarTotal.vue';
import { CheckoutDetails, PaymentMethod } from '@openticket/sdk-shop';
import EventCard from '@/pages/shop/components/EventCard.vue';
import CardHeading from '@/pages/shop/components/CardHeading.vue';
import DateBlock from '@/components/DateBlock.vue';
import CartReviewTicket from '@/pages/shop/components/CartReviewTicket.vue';
import CartReviewTotal from '@/pages/shop/components/CartReviewTotal.vue';
import { AppliedCoupon } from '@openticket/sdk-shop/lib/shop';
import CollapseCard from '@/pages/shop/components/CollapseCard.vue';
import CartReviewGlobalProduct from '@/pages/shop/components/CartReviewGlobalProduct.vue';
import EventSummaryHeader from '@/pages/shop/components/EventSummaryHeader.vue';

@Component({
    components: {
        EventSummaryHeader,
        CartReviewGlobalProduct,
        CollapseCard,
        CartReviewTotal,
        CartReviewTicket,
        DateBlock,
        CardHeading,
        EventCard,
        OrderBarTotal,
    },
})
export default class CartReview extends ShopModule {
    details: CheckoutDetails | null = null;

    checkoutListener!: string;

    method: PaymentMethod | null = null;

    showDetails = false;

    emptyCart = true;

    showGlobalProducts = false;

    totalTicketCount = 0;

    totalEventCount = 0;

    busy = false;

    coupons: {
        [coupon_guid: string]: AppliedCoupon;
    } = {};

    created(): void {
        this.checkoutListener = this.$shop.cart.on(
            'checkout',
            (path: string[], details: CheckoutDetails) => {
                this.details = details;

                if (!details.busy) {
                    this.updateValues();
                    this.busy = false;
                } else {
                    this.busy = true;
                }
            }
        );

        this.details = this.$shop.cart.checkout_details;
        this.updateValues();
    }

    updateValues(): void {
        this.method = this.$shop.cart.paymentMethod.item;

        this.coupons = this.$shop.cart.getAppliedCoupons();

        this.totalTicketCount = Object.keys(
            this.$shop.cart.flatItems.tickets
        ).length;

        this.totalEventCount = Object.keys(this.$shop.cart.items).length;

        this.emptyCart =
            this.totalEventCount === 0 &&
            this.$shop.cart.orphanedGlobalProducts.collection.length === 0;

        this.showGlobalProducts =
            this.$shop.cart.orphanedGlobalProducts.collection.length > 0;
    }

    destroyed(): void {
        this.$shop.off(this.checkoutListener);
    }
}
