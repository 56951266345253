import { i18n } from '@/base';
import Base from '@/Base.vue';
import Vue from 'vue';
import router from './router';
import store from './store';
import 'iframe-resizer/js/iframeResizer.contentWindow.js';
import { OpenTicketShop } from '@openticket/sdk-shop';
import { Logger } from '@openticket/lib-sdk-helpers';
import VueError from '@/error';
import { registerIframeResizerListener } from '@/utils/isIframeResizer';
import { IFramePageOptions } from 'iframe-resizer';
import { CookieManager } from '@/composables/cookies';

declare module 'vue/types/vue' {
    interface Vue {
        $cookies: CookieManager;
        $shop: OpenTicketShop;
        $logger: Logger;
    }
}

declare global {
    interface Window {
        Shop: OpenTicketShop;
        iFrameResizer: IFramePageOptions & {
            /**
             * Receive message posted from the parent page with the iframe.iFrameResizer.sendMessage() method.
             */
            onMessage?(message: any): void;

            /**
             * This function is called once iFrame-Resizer has been initialized after receiving a call from the parent page.
             */
            onReady?(): void;
        };
    }
}

const sessionId =
    new URLSearchParams(window.location.search).get('sessionId') || undefined;

const logger = (Vue.prototype.$logger = new Logger('shop', {
    sessionId,
}));

// Add shop SDK to vue prototype
Vue.prototype.$shop = window.Shop = Vue.observable(
    new OpenTicketShop({
        sessionId: logger.sessionId,
    })
);

new Vue({
    router,
    store,
    i18n,
    render: h => h(Base),
}).$mount('#app');

Vue.config.errorHandler = err => {
    logger.throw(new VueError('root', err));
};

registerIframeResizerListener();

window.addEventListener('message', (event: MessageEvent) => {
    if (
        typeof event.data === 'string' &&
        event.data.startsWith('window.scrollY:')
    ) {
        document.documentElement.style.setProperty(
            '--ot-injector-iframe-parent-scroll-top',
            `${event.data.slice(15)}px`
        );
    }
});
