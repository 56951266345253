





























































































































import { LocalStorage } from '@/utils';
import ShopModule, { ShopModuleValidationError } from './module';
import Component from 'vue-class-component';

// The new structure, extracted to be backwards compatible
type CachedRememberTermsUrls = {
    [url: string]: boolean;
};

type CachedRememberTerms = {
    [shop: string]: {
        [term: string]: boolean | CachedRememberTermsUrls;
    };
};

export const STORAGE_REMEMBER_TERMS_KEY = 'openticket_remember_terms';

@Component
export default class TermsModule extends ShopModule {
    /* SHOP MODULE */
    public scopes = ['terms'];

    public validate(mutate?: boolean): ShopModuleValidationError | null {
        const response = this.$shop.cart.validator.allTerms(mutate);

        if (response.valid) {
            return null;
        }

        return {
            message: 'Terms should be accepted',
            slug: 'shop.components.terms.should_be_accepted_error',
            payload: response.errors,
        };
    }

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    whitelabelName: string | null = null;
    companyName: string | null = null;
    companyTerms: string | null = null;
    hasCompanyTerms = false;

    rememberTerms = false;
    rememberTermsChecked: { [key: string]: boolean } = {};

    created(): void {
        this.whitelabelName = this.$shop.data.branding.name;
        this.companyName =
            this.$shop.data.company.coc_name || this.$shop.data.company.name;
        this.companyTerms = this.$shop.data.company_terms;
        this.rememberTerms = this.$settings?.static.shop.rememberTerms || false;

        this.checkRememberTerms();

        if (
            typeof this.companyTerms !== 'undefined' &&
            this.companyTerms !== null
        ) {
            this.hasCompanyTerms = true;
        }
    }

    get terms(): { [key: string]: boolean } {
        return this.$shop.cart.terms;
    }

    get term_urls(): string[] {
        const urls = [];

        const whitelabel_terms_url = this.$whitelabel.shop.terms_url;
        if (whitelabel_terms_url) {
            urls.push(whitelabel_terms_url);
        }

        if (this.companyTerms) {
            urls.push(this.companyTerms);
        }

        return urls;
    }

    async onInput(term: string, checked: boolean): Promise<void> {
        await this.$shop.cart.agreeToTerms(term, checked);
        this.isDirty = true;

        this.pruneRememberTerms();

        if (this.rememberTerms) {
            if (checked) {
                this.$set(this.rememberTermsChecked, term, false);
            } else {
                this.$delete(this.rememberTermsChecked, term);
                this.removeRememberTerms(term);
            }
        }
    }

    onRememberTermsInput(term: string, checked: boolean): void {
        if (checked) {
            this.addRememberTerms(term);
        } else {
            this.removeRememberTerms(term);
        }
    }

    async checkRememberTerms(): Promise<void> {
        const cachedRememberTerms = LocalStorage.get<CachedRememberTerms>(
            STORAGE_REMEMBER_TERMS_KEY
        );

        if (
            !this.rememberTerms &&
            cachedRememberTerms &&
            cachedRememberTerms[this.$shop.data.guid]
        ) {
            this.removeShopFromRememberTerms(
                cachedRememberTerms,
                this.$shop.data.guid
            );
            return;
        }

        if (!cachedRememberTerms || !Object.keys(cachedRememberTerms).length) {
            return;
        }

        const cachedRememberTermsByShop =
            cachedRememberTerms[this.$shop.data.guid];

        if (
            !cachedRememberTermsByShop ||
            !Object.keys(cachedRememberTermsByShop).length
        ) {
            return;
        }

        // Remove objects saved on the terms (That is deprecated)
        Object.keys(this.terms).forEach(term => {
            if (
                cachedRememberTermsByShop[term] &&
                typeof cachedRememberTermsByShop[term] === 'boolean'
            ) {
                this.removeRememberTerms(term);
                delete cachedRememberTermsByShop[term];
            }
        });

        // Check for every cached term whether the urls are the same as the urls
        //   of this instance.
        this.rememberTermsChecked = Object.fromEntries(
            Object.keys(this.terms).map(term => {
                const urlValues: boolean | CachedRememberTermsUrls =
                    cachedRememberTermsByShop[term] || {};

                // The urlValues should be an object
                // The urlValues count should match
                // The urlValues should match
                if (
                    typeof urlValues === 'boolean' ||
                    this.term_urls.length !== Object.keys(urlValues).length
                ) {
                    return [term, false];
                }

                return [
                    term,
                    this.term_urls.every((url: string) => !!urlValues[url]),
                ];
            })
        );

        Object.keys(this.terms).forEach(async term => {
            if (this.rememberTermsChecked[term]) {
                await this.$shop.cart.agreeToTerms(term, true);
            }
        });
    }

    removeRememberTerms(term: string): void {
        const shopGuid = this.$shop.data.guid;
        const cachedRememberTerms = LocalStorage.get<CachedRememberTerms>(
            STORAGE_REMEMBER_TERMS_KEY
        );

        if (!cachedRememberTerms || !cachedRememberTerms[shopGuid]) {
            return;
        }

        const shopCachedTerms = cachedRememberTerms[shopGuid];
        delete shopCachedTerms[term];

        if (!shopCachedTerms || !Object.keys(shopCachedTerms).length) {
            this.removeShopFromRememberTerms(cachedRememberTerms, shopGuid);
            return;
        }

        LocalStorage.set<CachedRememberTerms>(STORAGE_REMEMBER_TERMS_KEY, {
            ...cachedRememberTerms,
            [shopGuid]: shopCachedTerms,
        });
    }

    addRememberTerms(term: string): void {
        const shopGuid = this.$shop.data.guid;
        const cachedRememberTerms = LocalStorage.get<CachedRememberTerms>(
            STORAGE_REMEMBER_TERMS_KEY
        );

        // Set every url to true to add to term
        const urls = Object.fromEntries(this.term_urls.map(url => [url, true]));

        if (cachedRememberTerms) {
            LocalStorage.set<CachedRememberTerms>(STORAGE_REMEMBER_TERMS_KEY, {
                ...cachedRememberTerms,
                [shopGuid]: {
                    ...cachedRememberTerms[shopGuid],
                    [term]: urls,
                },
            });
        } else {
            LocalStorage.set<CachedRememberTerms>(STORAGE_REMEMBER_TERMS_KEY, {
                [shopGuid]: {
                    [term]: urls,
                },
            });
        }
    }

    removeShopFromRememberTerms(
        cachedRememberTerms: CachedRememberTerms,
        shopGuid: string
    ): void {
        delete cachedRememberTerms[shopGuid];

        // If the value is {}, completely remove the rememberTerms item from localStorage
        if (!cachedRememberTerms || !Object.keys(cachedRememberTerms).length) {
            LocalStorage.remove(STORAGE_REMEMBER_TERMS_KEY);
        } else {
            LocalStorage.set<CachedRememberTerms>(
                STORAGE_REMEMBER_TERMS_KEY,
                cachedRememberTerms
            );
        }
    }

    // Checks if there are terms remembered that are no longer applicable and removes them from the storage.
    pruneRememberTerms(): void {
        const cachedRememberTerms = LocalStorage.get<CachedRememberTerms>(
            STORAGE_REMEMBER_TERMS_KEY
        );

        if (
            !cachedRememberTerms ||
            !cachedRememberTerms[this.$shop.data.guid]
        ) {
            return;
        }

        Object.keys(cachedRememberTerms[this.$shop.data.guid]).forEach(
            savedTerm => {
                if (this.terms[savedTerm] === undefined) {
                    this.removeRememberTerms(savedTerm);
                }
            }
        );
    }
}
