











































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SplashScreen extends Vue {
    @Prop() visible!: boolean;
    @Prop() label!: string | null;
}
