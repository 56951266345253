

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { PaymentMethod } from '@openticket/sdk-shop';

import iDeal from '@/assets/images/brands/ideal.svg';
import Bancontact from '@/assets/images/brands/bancontact.svg';
import Sofort from '@/assets/images/brands/sofort.svg';
import CreditCard from '@/assets/images/brands/creditcard.svg';
import CreditCardCorporate from '@/assets/images/brands/creditcardcorporate.svg';
import AmericanExpress from '@/assets/images/brands/american_express.svg';
import PayPal from '@/assets/images/brands/paypal.svg';
import GiroPay from '@/assets/images/brands/giropay.svg';
import CarteBleue from '@/assets/images/brands/carte_bleue.svg';
import Invoice from '@/assets/images/brands/invoice.svg';
import SEPA from '@/assets/images/brands/sepa.svg';
import ApplePay from '@/assets/images/brands/apple_pay.svg';
import PodiumCadeaukaart from '@/assets/images/brands/podiumcadeaukaart.svg';
import FestivalCadeaukaart from '@/assets/images/brands/festival_cadeaukaart.svg';
import VVVCadeaukaart from '@/assets/images/brands/vvv.svg';
import Payconiq from '@/assets/images/brands/payconiq.svg';
import Dankort from '@/assets/images/brands/dankort.svg';
import Cash from '@/assets/images/brands/cash.svg';
import Pin from '@/assets/images/brands/pin.svg';
import Klarna from '@/assets/images/brands/klarna.svg';

interface PaymentMethodIcon {
    url: string;
    alt: string;
}

@Component
export default class PaymentMethodItemIcon extends Vue {
    @Prop() method!: PaymentMethod;

    demoPaymentUrl: string | null = null;

    listener!: number;

    get icon(): PaymentMethodIcon | null {
        const methodName: string = this.method.name
            .toLowerCase()
            .replace(/[^a-z0-9]/g, '');

        switch (methodName) {
            case 'demopayment':
                return this.demoPaymentUrl
                    ? {
                          url: this.demoPaymentUrl,
                          alt: 'Demo Payment',
                      }
                    : null;

            case 'ideal':
                return {
                    url: iDeal,
                    alt: 'iDeal',
                };

            case 'bancontact':
                return {
                    url: Bancontact,
                    alt: 'Bancontact',
                };

            case 'sofort':
                return {
                    url: Sofort,
                    alt: 'Sofort',
                };

            case 'creditcard':
            case 'creditcardconsumercard':
            case 'creditcardconsumercards':
            case 'creditcardmastercardvisa':
            case 'creditcardvisamastercard':
            case 'creditdebitcard':
                return {
                    alt: 'CreditCard',
                    url: CreditCard,
                };

            case 'creditcardcorporatecard':
            case 'creditcardcorporatecards':
            case 'creditcardbusinesscard':
            case 'creditcardbusinesscards':
                return {
                    alt: 'CreditCard (Corporate)',
                    url: CreditCardCorporate,
                };

            case 'amex':
            case 'americanexpress':
            case 'facamex':
                return {
                    url: AmericanExpress,
                    alt: 'American Express',
                };

            case 'paypal':
                return {
                    url: PayPal,
                    alt: 'PayPal',
                };

            case 'giropay':
                return {
                    url: GiroPay,
                    alt: 'GiroPay',
                };

            case 'cartebleue':
                return {
                    url: CarteBleue,
                    alt: 'Carte Bleue',
                };

            case 'invoice':
            case 'opfactuur':
                return {
                    url: Invoice,
                    alt: 'Invoice',
                };

            case 'sepa':
            case 'sepadirectdebit':
                return {
                    url: SEPA,
                    alt: 'Single EURO Payments Area',
                };

            case 'applepay':
                return {
                    url: ApplePay,
                    alt: 'Apple Pay',
                };

            case 'festivalcadeaukaart':
                return {
                    url: FestivalCadeaukaart,
                    alt: 'Festival Cadeaukaart',
                };

            case 'podiumcadeaukaart':
                return {
                    url: PodiumCadeaukaart,
                    alt: 'Podium Cadeaukaart',
                };

            case 'vvv':
            case 'vvvcadeaukaart':
            case 'vvvgiftcard':
                return {
                    url: VVVCadeaukaart,
                    alt: 'VVV Cadeaukaart',
                };

            case 'payconiq':
                return {
                    url: Payconiq,
                    alt: 'Payconiq',
                };

            case 'dankort':
                return {
                    url: Dankort,
                    alt: 'Dankort',
                };

            case 'cash':
            case 'adyenposcash':
                return {
                    url: Cash,
                    alt: 'Cash',
                };

            case 'pin':
            case 'adyenpospin':
                return {
                    url: Pin,
                    alt: 'Pin',
                };

            case 'creditcardmastercardvisaamericanexpressfac':
            case 'paylink':
                return {
                    url: Pin,
                    alt: 'Pay',
                };
            case 'klarna':
                return {
                    url: Klarna,
                    alt: 'Klarna',
                };
        }

        return {
            url: Pin,
            alt: 'Pay',
        };
    }

    created(): void {
        this.listener = this.$style.addThemeListener(() => {
            this.setDemoPaymentUrl();
        });

        this.setDemoPaymentUrl();
    }

    destroyed(): void {
        if (this.listener) {
            this.$style.removeThemeListener(this.listener);
        }
    }

    setDemoPaymentUrl(): void {
        switch (this.$style.getAppliedTheme()) {
            case 'light':
                this.demoPaymentUrl = this.$whitelabel.resources.icon;
                break;
            case 'dark':
                this.demoPaymentUrl = this.$whitelabel.resources.iconDark;
                break;
        }
    }
}
