







































import Component from 'vue-class-component';
import ShopModuleComponent from './module';
import { Collapse, Event } from '@openticket/sdk-shop';
import EventGroupItemTicket from '../components/EventGroupItemTicket.vue';
import { Prop } from 'vue-property-decorator';
import CollapseCard from '../components/CollapseCard.vue';

@Component({
    components: {
        EventGroupItemTicket,
        CollapseCard,
    },
})
export default class TicketCollapse extends ShopModuleComponent {
    /* SHOP MODULE */

    public isDirty = true;

    public static isReady(): null {
        return null;
    }

    /* END SHOP MODULE */

    @Prop() guid!: string;
    @Prop() color!: string;

    isOpen = false;

    created(): void {
        this.isOpen = this.collapse.start_open;
    }

    get collapse(): Collapse {
        return this.$shop.data.collapses_map[this.guid];
    }

    get event(): Event {
        const event_id = this.collapse.tickets[0].event_id;
        return this.$shop.data.events_map[event_id];
    }
}
