



























import { ShopProduct } from '@openticket/sdk-shop';
import Component from 'vue-class-component';
import ShopModuleComponent from '../module';
import { getGlobalProduct } from './utils';

const CLASS_NAME = 'Co2Compensate';

@Component
export default class GreenProduct extends ShopModuleComponent {
    checked = false;
    productListener!: string;

    loading = false;

    created(): void {
        if (!this.product) {
            return;
        }

        this.updateChecked();

        this.productListener = this.$shop.cart.on(
            ['product', this.product.guid],
            () => {
                this.updateChecked();
            }
        );
    }

    beforeDestroy(): void {
        if (this.productListener) {
            this.$shop.cart.off(this.productListener);
        }
    }

    get product(): ShopProduct | undefined {
        return getGlobalProduct(
            this.$shop.data,
            CLASS_NAME,
            this.$route.params.eventId
        );
    }

    get price(): string {
        return this.$l.currency(
            this.product!.pricing.total_price,
            this.$shop.data.currency as string
        );
    }

    updateChecked(): void {
        const info = this.$shop.cart.getProductInfo(this.product!.guid);
        this.checked = info.count > 0;
    }

    async onValueChanged(value: boolean): Promise<void> {
        this.loading = true;

        try {
            if (value) {
                await this.$shop.cart.addProduct(this.product!.guid);
            } else {
                await this.$shop.cart.removeProduct(this.product!.guid);
            }
        } finally {
            this.loading = false;
        }
    }
}
