class LocalStorage {
    /**
     *  Wrapper around the localStorage.getItem method
     *
     *  The retrieved value is always decoded from json.
     *
     *  @param key
     */
    public get<T = any>(key: string): T | null {
        const rawItem: string | null = localStorage.getItem(key);

        if (!rawItem) {
            return null;
        }

        return JSON.parse(rawItem) as T;
    }

    /**
     *  Wrapper around the localStorage.setItem method
     *
     *  The value is always encoded to json before saving.
     *
     *  @param key
     *  @param value
     */
    public set<T = any>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    /**
     *  Wrapper around the localStorage.removeItem method
     *
     *  @param key
     */
    public remove(key: string) {
        localStorage.removeItem(key);
    }
}

export default new LocalStorage();
