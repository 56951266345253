import Vue from 'vue';
import SwinkelsGroupTickets from './swinkels/GroupTickets.vue';
import SwinkelsIndividualTickets from './swinkels/IndividualTickets.vue';
import SwinkelsGroupMenu from './swinkels/GroupMenu.vue';
import SwinkelsGroupBeerPackage from './swinkels/GroupBeerPackage.vue';
import IndividualNoTicketMetadata from './swinkels/IndividualNoTicketMetadata.vue';
import ExternalValidationForm from '@/pages/shop/modules/external/validationform/ExternalValidationForm.vue';

// SWINKELS GROUP
Vue.component('shop-module-swinkels-group-tickets', SwinkelsGroupTickets);
Vue.component('shop-module-swinkels-group-menu', SwinkelsGroupMenu);
Vue.component(
    'shop-module-swinkels-group-beer-package',
    SwinkelsGroupBeerPackage
);

// SWINKELS INDIVIDUAL
Vue.component(
    'shop-module-swinkels-individual-tickets',
    SwinkelsIndividualTickets
);
Vue.component(
    'shop-module-swinkels-individual-no-ticket-metadata',
    IndividualNoTicketMetadata
);

Vue.component('shop-module-external-validation-form', ExternalValidationForm);
