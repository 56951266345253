
























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { CartTicket, GroupProduct, ProductGroup } from '@openticket/sdk-shop';
import { getProductPrice } from './pricing';

@Component
export default class OptionalProductGroupSelectItem extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() productGroup!: ProductGroup;
    @Prop() triggerUpdate!: unknown;

    loading = false;

    selected: string | null = null;

    productListener!: string;
    options: { [key: string]: string } = {};

    created(): void {
        this.productListener = this.$shop.cart.on(
            ['ticket', this.ticket.id, 'product'],
            () => {
                this.update();
            }
        );

        this.update();
    }

    update(): void {
        this.updateSelected();
        this.updateOptions();
    }

    destroyed(): void {
        if (this.productListener) {
            this.$shop.cart.off(this.productListener);
        }
    }

    @Watch('ticket.item', { deep: true, immediate: true })
    updateOptions(): void {
        const map: { [key: string]: string } = {};
        for (const product of this.productGroup.products) {
            map[product.guid] =
                product.item.name + ` ( ${this.suffix(product)} )`;
        }

        this.options = map;
    }

    updateSelected(): void {
        for (const product of this.productGroup.products) {
            for (const cartProduct of this.ticket.products.collection) {
                if (product.guid === cartProduct.item.guid) {
                    this.selected = product.guid;

                    return;
                }
            }
        }

        this.selected = null;
    }

    suffix(product: GroupProduct): string {
        return this.$l.currency(
            getProductPrice(product, this.ticket),
            this.$shop.data.currency as string
        );
    }

    @Watch('triggerUpdate')
    onTriggerUpdate(): void {
        this.updateSelected();
    }

    onInput(guid: string | null): void {
        if (!guid) {
            // The select input itself does not have this option, so there is no remove event emitted.
            return;
        }

        this.$emit('add', guid, this.selected);
    }
}
