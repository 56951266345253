


































































import Vue from 'vue';
import Component from 'vue-class-component';
import CardHeading from './CardHeading.vue';
import DateBlock from '@/components/DateBlock.vue';
import { Prop } from 'vue-property-decorator';
import CollapseCard from '@/pages/shop/components/CollapseCard.vue';

@Component({
    components: {
        CollapseCard,
        CardHeading,
        DateBlock,
    },
})
export default class EventSummaryHeader extends Vue {
    @Prop() name!: string;
    @Prop({ default: null }) start!: Date | null;
    @Prop({ default: null }) end!: Date | null;
    @Prop({ default: null }) locationName!: string | null;
    @Prop({ default: null }) locationAddress!: string | null;
    @Prop({ type: Boolean, default: false }) showRightAction!: boolean;
}
