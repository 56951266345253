



























































































































































































































































































































import Component from 'vue-class-component';
import ShopModuleComponent, { ShopModuleValidationError } from '../module';
import CardHeading from '@/pages/shop/components/CardHeading.vue';
import EventGroupItem from '@/pages/shop/components/EventGroupItem.vue';
import {
    AddProductEvent,
    Branding,
    CartItemChildType,
    LogLevel,
    Price,
    ShopProduct,
} from '@openticket/sdk-shop';
import urlJoin from 'url-join';
import axios from 'axios';
import { TranslateResult } from '@openticket/vue-localization';
import { getGlobalProduct } from './utils';
import VueError from '@/error';

let coverGeniusChoiceMade = false;

const CLASS_NAME = 'CoverGenius';

interface QuoteModel {
    policy: {
        content: {
            disclaimer: string;
        };
    };
}

interface RequestModel {
    event_country: string;
    policy_type: string;
    policy_type_version: string;
    event_name: string;
    event_datetime: string;
    event_end_date: string;
    number_of_tickets: number;
    policy_start_date: string;
    tickets: { price: number }[];
    total_tickets_price: number;
}

interface RequestBody {
    customer_country: string;
    customer_language: string;
    currency: string;
    partner_subsidiary: string;
    request: RequestModel[];
}

export interface CoverGeniusEventTrackData {
    quote_id?: string;
    social_proof_counter?: number;
    displayed_price?: number;
}
export type CoverGeniusRequestEventTrackData = RequestBody;
export type CoverGeniusResponseEventTrackData = CoverGeniusEventTrackData;

// DD-COVER-GENIUS-2715 - No cover genius protection is offered for one page shops
// DD-COVER-GENIUS-2716 - Shops with Widget might add tickets that then are not taken into account when accepting
// the place order (not be fully added to the total). This might lead to weird unintended behaviour currently
// outside the scope

@Component({
    components: {
        EventGroupItem,
        CardHeading,
    },
})
export default class CoverGeniusProduct extends ShopModuleComponent {
    checked: null | boolean = null;
    cartProductListener!: string;
    dataProductListener!: string;
    localizationListener!: number;
    themeListener: number | null = null;
    isDark = false;
    loading = false;
    internalPrice = 0;
    partnerSlug: string | null = null;
    protectedValue: number | null = null;

    quote: QuoteModel | null = null;
    pdsUrl: string | null = null;
    trackingData: CoverGeniusEventTrackData = {} as CoverGeniusEventTrackData;

    get reasons(): TranslateResult {
        return this.$t('shop.components.cover_genius.covered.reasons');
    }

    async created(): Promise<void> {
        if (!this.product) {
            return;
        }

        this.themeListener = this.$style.addThemeListener(() => {
            this.setTheme();
        });
        this.setTheme();

        // Treat the slug attribute in the branding shop data as unknown
        // Only set the partner slug when we are sure this slug is a string
        const whitelabelBranding: Branding & { slug: unknown | null } = this
            .$shop.data.branding as Branding & { slug: unknown | null };
        if (typeof whitelabelBranding.slug === 'string') {
            this.partnerSlug = whitelabelBranding.slug;
        }

        const info: AddProductEvent = this.$shop.cart.getProductInfo(
            this.product!.guid
        );
        const isCoverable: boolean = await this.checkCoverage();
        if (info.count >= 1 && !isCoverable) {
            // DD-COVER-GENIUS-2713 - When a quote has been given, but after some changes to the cart
            // are made that make the order not coverable, then the original quote
            // (cover genius product) is automatically removed from the cart
            await this.onValueChanged(false);
        }

        this.cartProductListener = this.$shop.cart.on(
            ['product', this.product.guid],
            () => this.updateChecked()
        );

        this.dataProductListener = this.$shop.on(
            ['product', this.product.guid],
            () => this.updateChecked()
        );

        this.localizationListener = this.$localization.on(
            'locale-change',
            async () => {
                // Check coverage again
                await this.checkCoverage();
            }
        );

        this.updateChecked();
    }

    async checkCoverage(): Promise<boolean> {
        this.quote = null;
        this.pdsUrl = null;

        if (
            !process.env.VUE_APP_COVER_GENIUS_URL ||
            !process.env.VUE_APP_COVER_GENIUS_POLICY ||
            !process.env.VUE_APP_COVER_GENIUS_POLICY_VERSION ||
            // check if order is free
            this.$shop.cart.checkout_details.total_price === 0
        ) {
            return false;
        }

        const requests: RequestModel[] = [];
        const path = urlJoin(process.env.VUE_APP_COVER_GENIUS_URL, 'quote');

        let running_total_price = 0;

        for (let key in this.$shop.cart.items) {
            const event = this.$shop.cart.items[key];
            const tickets_price: { price: number }[] = [];

            let total_tickets_price = 0;
            for (let ticket_key in event[CartItemChildType.Tickets]
                .collection) {
                let price =
                    event[CartItemChildType.Tickets].collection[ticket_key]
                        .pricing.total_price;
                running_total_price += price;

                price = Math.ceil(price) * 0.01;

                tickets_price.push({ price: price });
                total_tickets_price = total_tickets_price + price;
            }

            if (
                this.$shop.data.company.country != null &&
                event.event.start != null &&
                event.event.end != null &&
                tickets_price.length > 0
            ) {
                const r: RequestModel = {
                    event_country: this.$shop.data.company.country,
                    event_name: event.event.name,
                    event_datetime: event.event.start.toISOString(),
                    event_end_date: event.event.end.toISOString(),
                    number_of_tickets: tickets_price.length,
                    policy_start_date: new Date().toISOString(),
                    policy_type: process.env
                        .VUE_APP_COVER_GENIUS_POLICY as string,
                    policy_type_version: process.env
                        .VUE_APP_COVER_GENIUS_POLICY_VERSION as string,
                    tickets: tickets_price,
                    total_tickets_price: total_tickets_price,
                };

                requests.push(r);
            } else {
                return false;
            }
        }

        Price.computeProductPricing(this.product!.pricing, running_total_price);
        this.protectedValue = running_total_price;

        try {
            // DD-COVER-GENIUS-2713 - Use the country from the company shop owner to make quote call
            const body = {
                customer_country: this.$shop.data.company.country,
                customer_language: this.$localization.locale.language,
                currency: this.$shop.data.currency,
                partner_subsidiary: this.partnerSlug,
                request: requests,
            };

            this.track(
                ['quote', 'requested'], // covergenius-quote-requested event source (rudderstack)
                body as CoverGeniusRequestEventTrackData
            );

            let res: {
                [key: number]: QuoteModel | null;
            } | null = null;

            const {
                data,
            }: {
                data: {
                    id?: string | null;
                    pds_url: string | null;
                    quotes: {
                        [key: number]: QuoteModel | null;
                    };
                };
            } = await axios.post(path, body);

            if (data) {
                res = data?.quotes;
            }

            if (res) {
                for (let key in res) {
                    const quote = res[key];

                    if (quote === null) {
                        delete this.trackingData.quote_id;
                        this.track(['quote', 'rejected']); // covergenius-quote-rejected event source (rudderstack)

                        return false;
                    }
                }

                // DD-COVER-GENIUS-2717 - For multi event/quote orders choose the first policy as the policy to show
                // the terms/wording link
                if (!!res[0] && res[0] != null) {
                    this.internalPrice = this.product!.pricing.total_price;
                    this.quote = res[0];
                    this.pdsUrl = data.pds_url || null;

                    this.trackingData.quote_id = data?.id || undefined;
                    this.track(['quote', 'received'], this.trackingData); // covergenius-quote-received event source (rudderstack)

                    return true;
                }
            }
        } catch (e) {
            this.$shop.log.create(
                LogLevel.Warning,
                ['component', 'cover_genius', 'quote', 'failed'],
                { error: e }
            );
        }

        delete this.trackingData.quote_id;
        this.track(['quote', 'rejected']); // covergenius-quote-rejected event source (rudderstack)

        return false;
    }

    public validate(): ShopModuleValidationError | null {
        if (
            coverGeniusChoiceMade ||
            !this.orderIsPaid ||
            !this.product ||
            !this.quote
        ) {
            return null;
        }

        return {
            message:
                'A choice should be made whether to make the order refundable.',
            slug: 'shop.components.cover_genius.should_make_choice',
        };
    }

    beforeDestroy(): void {
        if (this.cartProductListener) {
            this.$shop.cart.off(this.cartProductListener);
        }

        if (this.dataProductListener) {
            this.$shop.cart.off(this.dataProductListener);
        }

        if (this.localizationListener) {
            this.$localization.off(this.localizationListener);
        }
    }

    destroyed(): void {
        if (this.themeListener !== null) {
            this.$style.removeThemeListener(this.themeListener);
        }
    }

    setTheme(): void {
        switch (this.$style.getAppliedTheme()) {
            case 'dark':
                this.isDark = true;
                break;
            case 'light':
            default:
                this.isDark = false;
                break;
        }
    }

    get orderIsPaid(): boolean {
        return this.$shop.cart.checkout_details.total_price > 0;
    }

    get product(): ShopProduct | undefined {
        return getGlobalProduct(
            this.$shop.data,
            CLASS_NAME,
            this.$route.params.eventId
        );
    }

    get coverGeniusPeopleCount(): number {
        // This formula is close enough to not be a lie, yet does not give away exact counts
        // https://app.clickup.com/t/86by5urvg
        const socialProofCounter =
            8159 + Math.floor((Date.now() / 1000 - 1707433206) / 86400);
        this.trackingData.social_proof_counter = socialProofCounter;
        return socialProofCounter;
    }

    get price(): string {
        if (this.internalPrice === 0) {
            delete this.trackingData.displayed_price;
            return '';
        }

        this.trackingData.displayed_price = this.internalPrice;
        return `+ ${this.$l.currency(
            this.internalPrice,
            this.$shop.data.currency as string
        )}`;
    }

    get totalProtectedValue(): number {
        return this.protectedValue || 0;
    }

    updateChecked(): void {
        const info: AddProductEvent = this.$shop.cart.getProductInfo(
            this.product!.guid
        );

        if (info.count > 0) {
            this.checked = true;
        } else if (coverGeniusChoiceMade) {
            this.checked = false;
        } else {
            this.checked = null;
        }
    }

    async selectCoverGenius(): Promise<void> {
        if (this.checked) {
            return;
        }

        return this.onValueChanged(true);
    }

    async deselectCoverGenius(): Promise<void> {
        coverGeniusChoiceMade = true;

        if (!this.checked) {
            this.checked = false;

            this.track(['product', 'deselected'], this.trackingData); // covergenius-product-deselected event source (rudderstack)

            return;
        }

        return this.onValueChanged(false);
    }

    async onValueChanged(value: boolean): Promise<void> {
        this.loading = true;

        try {
            if (value) {
                await this.$shop.cart.addProduct(this.product!.guid);

                this.track(['product', 'selected'], this.trackingData); // covergenius-product-selected event source (rudderstack)
            } else {
                await this.$shop.cart.removeProduct(this.product!.guid);

                this.track(['product', 'deselected'], this.trackingData); // covergenius-product-deselected event source (rudderstack)
            }
        } finally {
            coverGeniusChoiceMade = true;
            this.loading = false;
        }
    }

    track(
        path: string[],
        data?:
            | CoverGeniusEventTrackData
            | CoverGeniusResponseEventTrackData
            | CoverGeniusRequestEventTrackData
    ): void {
        try {
            this.$shop.events.emit(['covergenius', ...path], data);
        } catch (e) {
            // Should not block
            this.$logger.log(
                new VueError('Emit CoverGenius tracking event failed', e)
            );
        }
    }
}
