
















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Prop, Ref, Watch } from 'vue-property-decorator';
import { CookieManager } from '../../composables/cookies';
import CookieWallSection from './CookieWallSection.vue';
import CookieWallToggles from './CookieWallToggles.vue';

const states: {
    readonly landing: 'landing';
    readonly configure: 'configure';
    readonly error: 'error';
} = {
    landing: 'landing' as const,
    configure: 'configure' as const,
    error: 'error' as const,
} as const;

@Component({
    components: {
        CookieWallToggles,
        CookieWallSection,
    },
})
export default class CookieWall extends Vue {
    @Ref('cookie-dialog')
    cookieDialog!: HTMLDialogElement;

    @Inject({ from: 'cookies', default: null })
    cookieManager!: CookieManager | null;

    @Prop({ default: 0 })
    triggerCookieWallOpen!: number;

    state: typeof states[keyof typeof states] = states.landing;
    states = states;

    @Watch('triggerCookieWallOpen')
    openCookieWall(): void {
        this.state = states.landing;
        this.showDialog();
    }

    mounted(): void {
        if (!this.cookieDialog) {
            return;
        }

        if (typeof this.cookieDialog.showModal !== 'function') {
            this.state = states.error;

            // TODO @openticket/lib-log
            console.error(
                'The dialog API is not supported by this browser. Cannot manage cookies.'
            );

            return;
        }

        const cookieManager = this.cookieManager;

        if (!cookieManager) {
            this.state = states.error;

            // TODO @openticket/lib-log
            console.error(
                'The cookie manager is not available. Cannot manage cookies.'
            );

            return;
        }

        void (async () => {
            if (cookieManager.state === CookieManager.pending) {
                await cookieManager.loaded;
            }

            if (cookieManager.state !== CookieManager.complete) {
                this.showDialog();
            }
        })();
    }

    showDialog() {
        try {
            // Prevent this cookie wall from stealing focus from the host page
            this.cookieDialog.inert = true;
            this.cookieDialog.showModal();
        } finally {
            this.cookieDialog.inert = false;
        }
    }

    acceptAll(): void {
        try {
            this.cookieManager?.acceptAll();

            this.cookieDialog?.close();
        } catch (e) {
            this.state = states.error;
            // TODO @openticket/lib-log
            console.error('Failed to process cookie dialog: acceptAll', e);
        }
    }

    closeOnError(): void {
        try {
            this.cookieManager?.clear();
        } catch (e) {
            this.state = states.error;
            // TODO @openticket/lib-log
            console.error('Failed to process cookie dialog: closeOnError', e);
        }

        this.cookieDialog?.close();
    }

    confirm(): void {
        try {
            this.cookieManager?.confirm();

            this.cookieDialog?.close();
        } catch (e) {
            this.state = states.error;
            // TODO @openticket/lib-log
            console.error('Failed to process cookie dialog: confirm', e);
        }
    }

    reject(): void {
        try {
            this.cookieManager?.reject();

            this.cookieDialog?.close();
        } catch (e) {
            this.state = states.error;
            // TODO @openticket/lib-log
            console.error('Failed to process cookie dialog: reject', e);
        }
    }

    goToLanding(): void {
        this.state = states.landing;
    }

    goToConfigure(): void {
        this.state = states.configure;
    }

    selectAll(): void {
        try {
            this.cookieManager?.selectAll();
        } catch (e) {
            this.state = states.error;
            // TODO @openticket/lib-log
            console.error('Failed to process cookie dialog: selectAll', e);
        }
    }

    deselectAll(): void {
        try {
            this.cookieManager?.deselectAll();
        } catch (e) {
            this.state = states.error;
            // TODO @openticket/lib-log
            console.error('Failed to process cookie dialog: deselectAll', e);
        }
    }
}
