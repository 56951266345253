















































































































































































































import {
    CartTicket,
    Event,
    GroupProduct,
    ProductGroup,
    ReservationExpiration,
    Ticket,
} from '@openticket/sdk-shop';
import Component from 'vue-class-component';
import OptionalItem from '@/pages/shop/components/OptionalProductGroup/OptionalItem.vue';
import { Prop } from 'vue-property-decorator';
import ShopModuleComponent, { ShopModuleValidationError } from '../../module';

const LANGUAGES: {
    [key: string]: string;
} = {
    EN: 'English',
    NL: 'Nederlands',
    FR: 'Francais',
};

@Component({
    components: {
        OptionalItem,
    },
})
export default class SwinkelsGroupTickets extends ShopModuleComponent {
    @Prop() productGroupNames!: string[];
    @Prop() languageSelect!: boolean;
    @Prop() languageOptions!: string[];
    @Prop() languageDefault!: string;
    @Prop() groupSize!: number;

    public scopes = ['tickets'];

    public static isReady(): null {
        return null;
    }

    public validate(): ShopModuleValidationError | null {
        if (Object.keys(this.$shop.cart.flatItems.tickets).length > 0) {
            return null;
        }

        return {
            module: 'tickets',
            message: 'No tickets selected',
        };
    }

    events: Event[] = [];
    cartTicket: CartTicket | null = null;

    busy = false;
    timerListener: string | null = null;

    groupSizeTotal = 0;
    selectedLanguage: string | null = null;

    created(): void {
        this.events = this.$shop.data.events;

        if (this.languageSelect) {
            this.selectedLanguage = this.languageDefault;
        }

        this.$shop.on('update', async () => {
            this.events = this.$shop.data.events;
            this.cartTicket = null;
            await this.$shop.cart.removeAllItems();
        });

        this.timerListener = this.$shop.on(
            'timer',
            async (
                path: string[],
                data: ReservationExpiration & { timeout: boolean }
            ) => {
                // This means a timeout on the cart has occurred and all reservations have been released
                if (!data.secondsRemaining) {
                    this.cartTicket = null;

                    // Force the component to recheck isActive for all tickets
                    this.$forceUpdate();
                }
            }
        );

        for (const ticket of this.events[0].tickets) {
            const info = this.$shop.cart.getTicketInfo(ticket.guid);

            if (info.count > 0) {
                this.cartTicket = this.$shop.cart.flatItems.tickets[
                    info.items[0].id
                ];
            }
        }

        this.groupSizeTotal = this.calculateGroupSize();
        this.$shop.cart.onCheckoutDetails(() => {
            this.groupSizeTotal = this.calculateGroupSize();
        });
    }

    get languages(): { [key: string]: string } {
        const map: { [key: string]: string } = {};
        for (const language of this.languageOptions) {
            if (LANGUAGES[language]) {
                map[language] = LANGUAGES[language];
            }
        }
        return map;
    }

    async selectLanguage(language: string): Promise<void> {
        this.selectedLanguage = language;

        await this.$shop.cart.removeAllItems();
        this.cartTicket = null;
    }

    calculateGroupSize(): number {
        if (!this.cartTicket) {
            return 0;
        }

        let count = this.groupSize;
        for (const product of this.productGroupInstance.products) {
            const productInfo = this.$shop.cart.getTicketProductInfo(
                this.cartTicket.id,
                product.guid
            );
            count = count + productInfo.count;
        }
        return count;
    }

    get tickets(): Ticket[] {
        if (this.selectedLanguage) {
            return this.events[0].tickets.filter((ticket: Ticket) =>
                ticket.name.includes(`- ${this.selectedLanguage}`)
            );
        }

        return this.events[0].tickets;
    }

    async select(ticket: string): Promise<void> {
        const ticketObj = this.$shop.data.tickets_map[ticket];
        if (!ticketObj || ticketObj.status !== 'available') {
            return;
        }

        this.busy = true;
        let isReserved = true;

        try {
            const isAlreadyActive = this.isActive(ticket);

            await this.$shop.cart.removeAllItems();
            isReserved = false;

            if (!isAlreadyActive) {
                const event = await this.$shop.cart.addTicket(ticket);
                this.cartTicket = this.$shop.cart.flatItems.tickets[
                    event.items[0].id
                ];
                isReserved = true;
            } else {
                this.cartTicket = null;
            }
        } catch (e) {
            if (e._isOpenTicketLogMessage) {
                this.$notifications.danger(this.$t(e.slug) as string);
            } else {
                throw e;
            }

            if (!isReserved) {
                this.cartTicket = null;
            }
        } finally {
            this.busy = false;
        }
    }

    statusSlug(ticket: string): string {
        const ticketObj = this.$shop.data.tickets_map[ticket];
        if (!ticketObj) {
            return '';
        }

        switch (ticketObj.status) {
            case 'available':
                return 'shop.common.ticket_status.available';
            case 'not_sold_right_now':
                return 'shop.common.ticket_status.not_sold_right_now';
            case 'sold_out':
                return 'shop.common.ticket_status.sold_out';
        }

        // If not recognized, just return the status.
        // This 'slug' will most likely not be present in translation messages
        // and thus be shown as-is.
        return ticketObj.status || '';
    }

    isActive(ticket: string): boolean {
        const info = this.$shop.cart.getTicketInfo(ticket);

        return info.count > 0;
    }

    get productGroupInstance(): ProductGroup {
        if (this.cartTicket) {
            for (const group of this.$shop.data.groups) {
                if (!group.name) {
                    continue;
                }

                if (
                    this.productGroupNames.includes(group.name) &&
                    this.cartTicket.item.guid === group.ticket_id
                ) {
                    return group;
                }
            }
        }

        throw Error('ProductGroup not found');
    }

    get products(): GroupProduct[] {
        return this.productGroupInstance.products;
    }
}
