























import { CartTicket, GroupProduct, ProductGroup } from '@openticket/sdk-shop';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getProductPrice } from './pricing';

@Component
export default class OptionalProductGroupMultiSelectItem extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() productGroup!: ProductGroup;
    @Prop() max?: number;

    value: string[] = [];

    productListener!: string;
    pending = false;
    pendingTimeout?: number;
    options: { [key: string]: string } = {};

    created(): void {
        this.productListener = this.$shop.cart.on(
            ['ticket', this.ticket.id, 'product'],
            () => {
                this.update();
            }
        );

        this.update();
    }

    update(): void {
        this.updateValue();
        this.updateOptions();
    }

    destroyed(): void {
        if (this.productListener) {
            this.$shop.cart.off(this.productListener);
        }
    }

    @Watch('$shop.cart.checkout_details.busy', { immediate: true })
    changePending(): void {
        clearTimeout(this.pendingTimeout);
        this.pending = this.$shop.cart.checkout_details.busy;
        this.pendingTimeout = window.setTimeout(() => {
            this.pending = false;
        }, 1000);
    }

    @Watch('ticket.item', { deep: true, immediate: true })
    updateOptions(): void {
        const map: { [key: string]: string } = {};
        for (const product of this.productGroup.products) {
            map[product.guid] = `${product.item.name} ( ${this.suffix(
                product
            )} )`;
        }

        this.options = map;
    }

    suffix(product: GroupProduct): string {
        return this.$l.currency(
            getProductPrice(product, this.ticket),
            this.$shop.data.currency as string
        );
    }

    updateValue(): void {
        const value: string[] = [];
        for (const product of this.productGroup.products) {
            for (const cartProduct of this.ticket.products.collection) {
                if (product.guid === cartProduct.item.guid) {
                    value.push(product.guid);
                }
            }
        }
        this.value = value;
    }

    onValueChanged(newValue: string[]): void {
        for (const guid of [...newValue, ...this.value]) {
            if (!newValue.includes(guid)) {
                this.$emit('remove', guid);
            } else if (!this.value.includes(guid)) {
                this.value.push(guid);
                this.$emit('add', guid);
            }
        }
    }

    private maxWarning(max: string): string {
        return this.$t(
            'shop.components.option_product_group.multi_select_item.max_warning',
            { max }
        ) as string;
    }

    selectionLabel(values: unknown[], isOpen: boolean): string {
        if (values.length || isOpen) {
            return this.$tc(
                'shop.components.option_product_group.multi_select_item.selection_label',
                values.length
            ) as string;
        }

        return '';
    }
}
