

















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';

import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import { Prop } from 'vue-property-decorator';
import { containsDate } from '@/utils';

@Component({
    components: {
        DatePick,
    },
})
export default class DatePicker extends Vue {
    @Prop() date!: string;
    @Prop() enabledDates!: { [key: string]: any };

    isDisabled(date: Date): boolean {
        return !containsDate(this.enabledDates, date);
    }

    get days(): string[] {
        const dayNames = Array.from(this.$localization.locale.dayNamesAbbr);
        const sunday = dayNames.shift();
        dayNames.push(sunday as string);
        return dayNames;
    }

    get months(): string[] {
        return this.$localization.locale.monthNamesWide;
    }
}
