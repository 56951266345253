import Vue from 'vue';

import { intToDuration } from '@/utils';

Vue.filter('currency', function(value: number) {
    if (value === null || value === undefined) return '€ 0.00';
    value = value / 100;
    if (value.toFixed(2).split('.')[1] == '00') {
        return '€ ' + value.toFixed(0) + '.00';
    } else {
        return '€ ' + value.toFixed(2);
    }
});

Vue.filter('duration', function(seconds: number) {
    return intToDuration(seconds);
});
