




















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import OrderBarTotal from '@/pages/shop/components/OrderBarTotal.vue';
import { CheckoutDetails, PaymentMethod } from '@openticket/sdk-shop';
import EventCard from '@/pages/shop/components/EventCard.vue';
import CardHeading from '@/pages/shop/components/CardHeading.vue';
import DateBlock from '@/components/DateBlock.vue';
import CartReviewTicket from '@/pages/shop/components/CartReviewTicket.vue';
import { Prop } from 'vue-property-decorator';
import { AppliedCoupon } from '@openticket/sdk-shop/lib/shop';

export interface FeeValue {
    fixed: number;
    percentage: number;
    total: number;
}

export interface FeeCollection {
    booking_fee: FeeValue;
    order_fee: FeeValue;
    transaction_fee: FeeValue;
}

@Component({
    components: {
        CartReviewTicket,
        DateBlock,
        CardHeading,
        EventCard,
        OrderBarTotal,
    },
})
export default class CartReviewTotal extends Vue {
    @Prop({ required: true }) details!: CheckoutDetails;
    @Prop({ required: true }) method!: PaymentMethod;
    @Prop({ default: true }) emptyCart!: boolean;
    @Prop({ default: {} }) coupons!: {
        [coupon_guid: string]: AppliedCoupon;
    };

    get fees(): FeeCollection {
        return this.details;
    }

    get bookingFeeSlug(): string {
        return this.feeSlug(
            this.fees.booking_fee.fixed,
            this.fees.booking_fee.percentage
        );
    }

    get paymentMethodFeeSlug(): string {
        return this.feeSlug(
            this.fees.transaction_fee.fixed,
            this.fees.transaction_fee.percentage
        );
    }

    feeSlug(fixed: number, percentage: number): string {
        if (fixed !== 0) {
            if (percentage !== 0) {
                return 'shop.components.cart_review.fees.fixed_percentage';
            } else {
                return 'shop.components.cart_review.fees.fixed';
            }
        }

        if (percentage !== 0) {
            return 'shop.components.cart_review.fees.percentage';
        }

        return 'shop.components.cart_review.fees.none';
    }
}
