var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop-module shop-module-terms"},[_c('div',{staticClass:"ot-card"},_vm._l((Object.keys(_vm.terms)),function(term){return _c('div',{key:term},[_c('div',{staticClass:"ot-checkbox terms"},[_c('input',{staticClass:"terms-checkbox",attrs:{"type":"checkbox","id":("terms-" + term)},domProps:{"checked":_vm.terms[term]},on:{"click":function($event){return _vm.onInput(term, $event.target.checked)}}}),_c('label',{staticClass:"terms-label",attrs:{"for":("terms-" + term)}},[_c('i18n',{attrs:{"path":"shop.components.terms.first"},scopedSlots:_vm._u([{key:"tos",fn:function(){return [_c('a',{staticClass:"terms-tos-first ot-link",attrs:{"href":_vm.hasCompanyTerms
                                        ? _vm.companyTerms
                                        : _vm.$whitelabel.shop.terms_url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.$t('shop.components.terms.tos')))])]},proxy:true},{key:"entity",fn:function(){return [_c('span',{staticClass:"terms-entity terms-entity-first"},[_vm._v(_vm._s(_vm.hasCompanyTerms ? _vm.companyName : _vm.whitelabelName))])]},proxy:true}],null,true)}),(_vm.hasCompanyTerms)?_c('i18n',{attrs:{"path":"shop.components.terms.second"},scopedSlots:_vm._u([{key:"tos",fn:function(){return [_c('a',{staticClass:"terms-tos-second ot-link",attrs:{"href":_vm.$whitelabel.shop.terms_url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(_vm._s(_vm.$t('shop.components.terms.tos')))])]},proxy:true},{key:"entity",fn:function(){return [_c('span',{staticClass:"terms-entity terms-entity-second"},[_vm._v(_vm._s(_vm.whitelabelName))])]},proxy:true}],null,true)}):_vm._e(),_c('span',{staticClass:"ot-input-label--required"},[_vm._v("*")]),(
                            _vm.rememberTerms &&
                                _vm.terms[term] &&
                                _vm.rememberTermsChecked[term]
                        )?_c('span',{staticClass:"terms terms-remember ot-input-sublabel"},[_vm._v(" "+_vm._s(_vm.$t('shop.components.terms.remember.agreed'))+" ")]):_vm._e()],1),(_vm.rememberTerms && !_vm.rememberTermsChecked[term])?_c('div',{staticClass:"ot-checkbox terms terms-remember",attrs:{"disabled":!_vm.terms[term]}},[_c('input',{staticClass:"terms-checkbox",attrs:{"type":"checkbox","id":("terms-" + term + "-remember")},domProps:{"checked":_vm.rememberTermsChecked[term] && _vm.terms[term]},on:{"click":function($event){return _vm.onRememberTermsInput(
                                term,
                                $event.target.checked
                            )}}}),_c('label',{staticClass:"terms-label",attrs:{"for":("terms-" + term + "-remember")}},[_vm._v(" "+_vm._s(_vm.$t('shop.components.terms.remember.label'))+" ")])]):_vm._e()])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }