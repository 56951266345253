




import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Logo extends Vue {
    url: string | null = null;
    listener!: number;

    created(): void {
        this.setURL();

        this.listener = this.$style.addThemeListener(() => {
            this.setURL();
        });
    }

    setURL(): void {
        switch (this.$style.getAppliedTheme()) {
            case 'light':
                this.url = this.$whitelabel.resources.spinner;
                break;
            case 'dark':
                this.url = this.$whitelabel.resources.spinnerDark;
                break;
        }
    }

    destroyed(): void {
        if (this.listener !== undefined) {
            this.$style.removeThemeListener(this.listener);
        }
    }
}
