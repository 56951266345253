import { BaseError, BaseSlugData } from '@openticket/lib-sdk-helpers';

export default class VueError extends BaseError<string> {
    private cause!: Error;

    constructor(message: string, cause: Error) {
        super(message);
        this.cause = cause;
    }

    public additionalFlatData(): { [key: string]: string } {
        return {
            cause: this.cause.toString(),
        };
    }

    public toSlug(): BaseSlugData {
        return {
            key: this.toString(),
        };
    }

    public toString(): string {
        return `Vue error: ${this.data}, ${this.cause}`;
    }
}
