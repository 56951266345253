































































































import type { CheckoutDetails } from '@openticket/sdk-shop';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import OrderBarTotalPart from './OrderBarTotalPart.vue';

export interface FeeValue {
    fixed: number;
    percentage: number;
    total: number;
}

export interface FeeCollection {
    booking_fee: FeeValue;
    order_fee: FeeValue;
    transaction_fee: FeeValue;
}

function determineSplitSlug(fee: FeeValue): string {
    if (!fee.percentage) {
        return 'shop.components.order_bar_total.value.without_split';
    }

    if (!fee.fixed) {
        return 'shop.components.order_bar_total.value.with_percentage';
    }

    return 'shop.components.order_bar_total.value.with_fixed_and_percentage';
}

@Component({
    components: { OrderBarTotalPart }
})
export default class OrderBarTotal extends Vue {

    @Prop({ required: true })
    details!: CheckoutDetails;

    @Prop({ default: false, type: Boolean })
    rightAlign!: boolean;

    get fees(): FeeCollection {
        return this.details;
    }

    get bifurcationSlug(): string {
        if (!this.fees.order_fee.total) {
            return 'shop.components.order_bar_total.bifurcation.no_fees';
        }

        if (!this.fees.transaction_fee.total) {
            return 'shop.components.order_bar_total.bifurcation.booking_fees';
        }

        if (!this.fees.booking_fee.total) {
            return 'shop.components.order_bar_total.bifurcation.transaction_fees';
        }

        return 'shop.components.order_bar_total.bifurcation.booking_transaction_fees';
    }

    get bookingFeeValueWithSplitSlug(): string {
        return determineSplitSlug(this.fees.booking_fee);
    }

    get transactionFeeValueWithSplitSlug(): string {
        return determineSplitSlug(this.fees.transaction_fee);
    }

    get currency(): string {
        return this.$shop.data.currency || 'XXX';
    }

}
