






































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import {
    CartTicket,
    CartMetadata,
    BaseMetadataItem,
} from '@openticket/sdk-shop';
import OptionalProductGroup from './OptionalProductGroup/OptionalProductGroup.vue';
import { ProductGroup } from '@openticket/sdk-shop';

@Component({
    components: {
        OptionalProductGroup,
    },
})
export default class TicketMetadataGroup extends Vue {
    @Prop() ticket!: CartTicket;
    @Prop() index!: number;
    @Prop() opened!: boolean;
    @Prop() showNext!: boolean;

    complete = false;

    hasAutofilled = false;
    autofilled: string[] = [];

    metadata: CartMetadata[] = [];

    created(): void {
        this.validate(false);

        this.metadata = this.getMetadataList();

        this.$shop.cart.on(['ticket', this.ticket.id], () => {
            this.metadata = this.getMetadataList();

            this.validate(false);
        });
    }

    get booker_information(): BaseMetadataItem[] {
        return [
            this.$shop.cart.booker.firstname,
            this.$shop.cart.booker.lastname,
            this.$shop.cart.booker.email,
            ...this.$shop.cart.metadataList,
        ];
    }

    get productGroups(): ProductGroup[] {
        return this.ticket.item.groups.filter(
            (group: ProductGroup) =>
                !(
                    group.min_bound === group.max_bound &&
                    group.min_bound > 0 &&
                    group.min_bound === group.products.length
                )
        );
    }

    input(): void {
        this.$emit('input');

        this.hasAutofilled = true;

        this.validate(false);
    }

    getMetadataList(): CartMetadata[] {
        let metadata: CartMetadata[] = this.ticket.metadataList;

        for (const product of this.ticket.products.collection) {
            const found = this.productGroups.find(pg =>
                pg.products.find(p => p.item.guid === product.item.guid)
            );

            if (!found) {
                metadata = metadata.concat(product.metadataList);
            }
        }

        return metadata;
    }

    product(): void {
        this.$emit('product');
        this.validate(false);
    }

    next(): void {
        if (this.validate(true)) {
            this.$emit('next');
        }
    }

    validate(mutate?: boolean): boolean {
        const metadata = this.$shop.cart.validator.ticketMetadata(
            this.ticket,
            mutate
        );

        const products = this.$shop.cart.validator.ticketProducts(
            this.ticket,
            mutate
        );

        return (this.complete = metadata.valid && products.valid);
    }

    autofill(): void {
        const booker_information = Object.values(this.$shop.cart.booker);
        const booker_metadata = this.$shop.cart.metadata;

        const autofilled = [];

        for (const meta of this.metadata) {
            // Check if the autofill name matches on booker information
            const booker_information_item: BaseMetadataItem = booker_information.find(
                (info: BaseMetadataItem) =>
                    info.item.auto_fill_facebook ===
                    meta.item.auto_fill_facebook
            );

            let metadata;

            if (booker_information_item) {
                metadata = booker_information_item;
            } else if (booker_metadata[meta.item.guid]) {
                metadata = booker_metadata[meta.item.guid];
            } else {
                continue;
            }

            if (!this.$shop.cart.validator.metadata(metadata, false).valid) {
                continue;
            }

            meta.value = metadata.value;

            autofilled.push(meta.item.guid);
        }

        this.hasAutofilled = true;
        this.autofilled = autofilled;

        setTimeout(() => {
            this.autofilled = [];
        }, 750);

        this.validate(false);
    }

    get overlapsWithBookerInformation(): boolean {
        let overlap = false;

        for (const meta of this.metadata) {
            let booker_metadata;

            if (this.$shop.cart.metadata[meta.item.guid]) {
                booker_metadata = this.$shop.cart.metadata[meta.item.guid];
            } else {
                const booker_information = Object.values(
                    this.$shop.cart.booker
                );

                const item = booker_information.find(
                    (info: BaseMetadataItem) =>
                        info.item.auto_fill_facebook ===
                        meta.item.auto_fill_facebook
                );

                if (item) {
                    booker_metadata = item;
                } else {
                    continue;
                }
            }

            overlap = !!booker_metadata;

            if (overlap) {
                break;
            }
        }

        return overlap;
    }
}
