






















import Vue from 'vue';
import Component from 'vue-class-component';
import {
    eventShouldShowWaitingList,
    getWaitingList,
    WaitingListModel,
} from '@/utils/waitingList';
import ShopHeader from '@/pages/shop/components/ShopHeader.vue';
import WaitingListDetails from '@/pages/shop/components/WaitingListDetails.vue';
import WaitingListWrapUp from '@/pages/shop/components/WaitingListWrapUp.vue';
import { Event } from '@openticket/sdk-shop/lib/shop/types';
import { State } from 'vuex-class';
import { ShopPage } from '@/pages/shop/store/types';
import { TranslateResult } from '@openticket/vue-localization';

const WaitingListStates = {
    Initializing: 'initializing',
    Form: 'form',
    Submitted: 'submitted',
    Closed: 'closed',
} as const;

type WaitingListState = typeof WaitingListStates[keyof typeof WaitingListStates];

@Component({
    components: { WaitingListWrapUp, WaitingListDetails, ShopHeader },
})
export default class WaitingList extends Vue {
    @State('pages') pages!: ShopPage[];
    state: WaitingListState = WaitingListStates.Initializing;
    waitingList: WaitingListModel | undefined;

    get pageTitle(): TranslateResult {
        if (this.state == WaitingListStates.Submitted) {
            return this.$t('shop.components.waiting_list.wrap_up.page_title');
        }
        return this.$t('shop.components.waiting_list.details.page_title');
    }

    get pageCount(): number {
        // TODO: Check with Mette whether 3 pages is also fine. Considering that the back button disappears on page 1.
        return 3;
    }

    get pageIndex(): number {
        if (this.state == WaitingListStates.Submitted) {
            return 3;
        }

        return 2;
    }

    get page(): ShopPage {
        const name = this.$route.params.page;
        return this.pages.find(
            (page: ShopPage) => page.name === name
        ) as ShopPage;
    }

    async created(): Promise<void> {
        const waitingListEventId: string = this.event_id;

        if (
            waitingListEventId &&
            waitingListEventId in this.$shop.data.events_map
        ) {
            const waitingList = await getWaitingList(waitingListEventId);

            if (
                waitingList &&
                eventShouldShowWaitingList(
                    waitingList.type,
                    this.$shop.data.events_map[waitingListEventId]
                )
            ) {
                this.waitingList = waitingList;
            } else {
                this.back();
            }
        } else {
            this.back();
        }

        this.state = WaitingListStates.Form;

        // Determine state, possible states:
        // closed: shop is not valid for waiting list, show message + button back to shop
        // form: show waiting list form
        // submitted: show success page (with later possible links to ticketswap + tixel)
        // (leave room for extra state: ask to allow linking data to external platform)
    }

    get event(): Event {
        return this.$shop.data.events_map[this.event_id];
    }

    get event_id(): string {
        const event_id = this.$route.query.waitingListEventId;
        if (Array.isArray(event_id)) {
            this.back();

            // Return to avoid TS treating event_id as an array, regardless of back()
            return '';
        }

        return event_id;
    }

    signupSubmitted(): void {
        this.state = WaitingListStates.Submitted;
    }

    back(): void {
        switch (this.state) {
            case WaitingListStates.Submitted:
            case WaitingListStates.Initializing:
            case WaitingListStates.Form:
            default:
                this.$router.push({
                    name: 'step',
                    query: {
                        ...this.$route.query,
                        waitingListEventId: undefined,
                    },
                });
        }
    }
}
