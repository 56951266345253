














import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Collapse extends Vue {
    start(el: HTMLElement): void {
        el.style.height = el.scrollHeight + 'px';
    }

    end(el: HTMLElement): void {
        el.style.height = '';
    }
}
