


















import ShopModuleComponent from './module';
import { Prop, Component } from 'vue-property-decorator';
import { Ticket, Event } from '@openticket/sdk-shop';
import EventGroupItemTicket from '../components/EventGroupItemTicket.vue';

@Component({
    components: {
        EventGroupItemTicket,
    },
})
export default class TicketModule extends ShopModuleComponent {
    @Prop() guid!: string;

    get ticket(): Ticket {
        return this.$shop.data.tickets_map[this.guid];
    }

    get event(): Event {
        return this.$shop.data.events_map[this.ticket.event_id];
    }
}
